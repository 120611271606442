import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { Router } from "@angular/router";
import { User } from "src/app/common/_models";
import { ServiceAuth, ServiceUser } from "src/app/common/_services";


@Component({
  templateUrl: './stage-user.bs.html',
  styleUrls: ['./stage-user.bs.scss']
})

export class BottomSheetStageUser {

  userFormGroup: FormGroup = new FormGroup({
    email: new FormControl({value: '', disabled: false}),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phone: new FormControl('', []),
    company: new FormControl({value: '', disabled: true}),
    role: new FormControl({value: '', disabled: true}),
    dateInvited: new FormControl({value: '', disabled: true}),
  });

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetStageUser>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: User,
    private userService: ServiceUser,
    private authService: ServiceAuth
  ) {
    console.log("User: " + JSON.stringify(data));

    if (data.dateInvited) data.dateInvited = new Date(data.dateInvited);
    this.userFormGroup.patchValue(data);
  }

  registerUser() {
    //console.log(this.userFormGroup.value);
    let tmpUser:User = this.userFormGroup.value;

    this.userService.stageUser(tmpUser).subscribe({
      next: data => {
        this.authService.logout();
      },
      error: error => {
      }
    });

    // Save and Close it
    this.bottomSheetRef.dismiss();
  }

}
