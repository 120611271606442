import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ArrowheadConfiguration, User } from "../_models";



@Injectable()

export class ServiceArrowhead {
  constructor(
    private http: HttpClient
  ) {
  }

  getConfiguration(): Observable<ArrowheadConfiguration> {
    return this.http.get<ArrowheadConfiguration>('/api/arrowhead');
  }

  saveConfiguration(configuration: ArrowheadConfiguration): Observable<any> {
    return this.http.post<any>('/api/arrowhead', configuration);
  }

  echoTest(): Observable<any> {
    return this.http.get<any>('/api/arrowhead/test');
  }
}
