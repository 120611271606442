import { Component, OnInit } from "@angular/core";
//import { LogInfo, LogssInfo } from "src/app/common/_models";
//import { ServicLogs } from "src/app/common/_services";

@Component({
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent implements OnInit {


  constructor(
    //private logsService: ServicLogs
  ) { }

  ngOnInit() {
    console.log("Logs");
  }

}
