import { Component, AfterViewInit, OnDestroy } from "@angular/core";
import * as L from 'leaflet';
import { PopupService } from 'src/app/common/_services';
import { ServiceMaps } from "src/app/common/_services";
import { MapData, PositionInfo } from "src/app/common/_models";
import { HttpErrorResponse } from "@angular/common/http";

const PULLING_RATE: number = 2000;

// Leadlet Markers
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

export interface PositionMarker {
  position: PositionInfo,
  marker: L.Marker
}


@Component({
  templateUrl: './osmmap.component.html',
  styleUrls: ['./osmmap.component.scss']
})

export class OsmmapComponent implements AfterViewInit, OnDestroy {

  private pullingTimer?: NodeJS.Timeout;
  private positionsMarkers: PositionMarker[] = [];
  private map?: L.Map;

  constructor(
    private mapService: ServiceMaps,
    private popupService: PopupService,
  ) { }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    // remove timers
    if (this.pullingTimer) {
      clearTimeout(this.pullingTimer);
    }
  }


  private initMap(): void {

    // Init ONLY the map

    let mapOptions: L.MapOptions = {
      center: [0, 0],
      worldCopyJump: true,
      zoom: 3
    };
    this.map = L.map('map', mapOptions);

    let tiles: L.TileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    // Starting timer if it's not already there
    if (!this.pullingTimer) {
      this.pullingTimer = setInterval(() => {
        this.pullingLoop();
      }, PULLING_RATE);
    }
  }

  private pullingLoop(): void {
    this.mapService.getPositions().subscribe({
      next: (data: MapData) => {
        // Check if there are new or updated
        data.positions.forEach(pos => {
          let oldPos: PositionInfo | null = this.findPosInfoByName(pos.name);
          if (oldPos == null) {
            this.addNewPosition(pos);
          } else {
            this.updatePosition(pos, oldPos);
          }
        });
        // Check if deleted
        this.findDeleted(data.positions);
      },
      error: error => {
        console.error(JSON.stringify(error));
      }
    });
  }

  private _findOnListByName(name: string, list: PositionMarker[]): PositionMarker | null {
    for (let i = 0; i < list.length; i++) {
      if (name == list[i].position.name) {
        return list[i];
      }
    }
    return null;
  }

  private _findOnListPositionInfoByName(name: string, list: PositionInfo[]): PositionInfo | null {
    for (let i = 0; i < list.length; i++) {
      if (name == list[i].name) {
        return list[i];
      }
    }
    return null;
  }

  private findMarkerInfoByName(name: string): PositionMarker | null {
    return this._findOnListByName(name, this.positionsMarkers);
  }

  private findPosInfoByName(name: string): PositionInfo | null {
    return this._findOnListPositionInfoByName(name, this.positionsMarkers.map(pm => pm.position));
  }

  private findPositionMarkerPosition(pm: PositionMarker): number {
    for (let i = 0; i < this.positionsMarkers.length; i++) {
      if (pm.position.name == this.positionsMarkers[i].position.name) {
        return i;
      }
    }
    return -1;
  }

  private removeMarkerPosition(pm: PositionMarker): void {
    let index: number = this.findPositionMarkerPosition(pm);
    if (index >= 0) {
      this.positionsMarkers.splice(index, 1);
    }
  }

  private findDeleted(newPos: PositionInfo[]): void {
    for (let i = this.positionsMarkers.length; i > 0; i--) {
      let index = i-1;
      let pos: PositionInfo | null = this._findOnListPositionInfoByName(this.positionsMarkers[index].position.name, newPos);
      if (!pos) {
        //console.warn("Need to remove: " + this.positionsMarkers[index].position.name);
        let pm: PositionMarker | null = this.findMarkerInfoByName(this.positionsMarkers[index].position.name);
        if (pm) {
          // Remove from the list
          this.removeMarkerPosition(pm);
          // Remove marker
          this.map?.removeLayer(pm.marker);
        }
      }
    }
  }

  private updatePosition(pos: PositionInfo, oldPos: PositionInfo) {
    if (pos.coordinates[0] != oldPos.coordinates[0] || pos.coordinates[1] != oldPos.coordinates[1]) {
      /*console.warn("Need to update: " + pos.name + "\n" +
        "Lat new:" + pos.coordinates[0] + " vs old:" + oldPos.coordinates[0] + "\n" +
        "Lon new:" + pos.coordinates[1] + " vs old:" + oldPos.coordinates[1]);*/

      let pm: PositionMarker | null = this.findMarkerInfoByName(pos.name);
      if (pm) {
        pm.position = pos;
        pm.marker.setLatLng([pm.position.coordinates[0], pm.position.coordinates[1]]);
      }

    }
  }

  private addNewPosition(pos: PositionInfo) {
    /*console.log("Create a new Marker for " + pos.name + "\n" +
      "Lat:" + pos.coordinates[0] + "\n" +
      "Lon:" + pos.coordinates[1]);*/
    let marker: L.Marker = L.marker([pos.coordinates[0], pos.coordinates[1]], { draggable: true });
    this.positionsMarkers.push({ position: pos, marker: marker });

    // Dragging
    marker.on('dragend', (e: L.DragEndEvent) => {
      let name:string = pos.name;
      let lat:number = e.target.getLatLng().lat;
      let lon:number = e.target.getLatLng().lng;
      console.log(name);
      console.log('lat: ' + lat);
      console.log('lng: ' + lon);
      // save point
      this.mapService.setPosition(name, lat, lon).subscribe({
        next: (data: MapData) => {
          // Check if there are new or updated
          data.positions.forEach(pos => {
            let oldPos: PositionInfo | null = this.findPosInfoByName(pos.name);
            if (oldPos == null) {
              this.addNewPosition(pos);
            } else {
              this.updatePosition(pos, oldPos);
            }
          });
          // Check if deleted
          this.findDeleted(data.positions);
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      });

    });

    let properties = { "name": pos.name, "lastUpdate": "unknown", "owner": "ThingWave AB" };
    marker.bindPopup(this.popupService.makePopup(properties));
    marker.bindTooltip(pos.name).openTooltip();
    if (this.map) marker.addTo(this.map);
  }

}
