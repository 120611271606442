<button mat-flat-button class="full-width" color="primary" [matMenuTriggerFor]="menuExport" [disabled]="loading || _services?.length==0 || _services == null">
  <mat-icon>file_download</mat-icon> Export<ng-container *ngIf="!loading; else loadingTemplate;"></ng-container>
</button>
<mat-menu #menuExport="matMenu">
  <button *ngFor="let format of exportFormats" mat-menu-item (click)="export(format.format);">
    <mat-icon>description</mat-icon> {{ format.text }}
  </button>
</mat-menu>


<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>