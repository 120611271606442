<div class="title">
	<h2 align="end">Arrowhead Configuration</h2>
</div>

<div *ngIf="!loading; else loadingTemplate;">
	<form [formGroup]="ahFormGroup" (submit)="saveChanges()">

		<p>
			<mat-checkbox formControlName="enabled">Enable Arrowhead support</mat-checkbox>
		</p>


		<div>
			<mat-form-field class="mid-width">
				<mat-label>System Name</mat-label>
				<input matInput formControlName="systemName">
			</mat-form-field>

		</div>
		<div>
			<mat-form-field class="mid-width">
				<mat-label>Service Registry endpoint</mat-label>
				<input matInput formControlName="serviceRegistry">
			</mat-form-field>
		</div>

		<mat-grid-list cols="3" rowHeight="3:2">

			<mat-grid-tile>
				<div class="full-width">
					<h3>CA File</h3>
					<tools-files-drop [title]="caFileName" (droppedFilesEvent)="droppedCAFilesEvent($event)" formats=".pem, .key">
					</tools-files-drop>
				</div>
			</mat-grid-tile>

			<mat-grid-tile>
				<div class="full-width">
					<h3>Certificate File</h3>
					<tools-files-drop [title]="certFileName" (droppedFilesEvent)="droppedCertFilesEvent($event)"
						formats=".pem, .key">
					</tools-files-drop>
				</div>
			</mat-grid-tile>

			<mat-grid-tile>
				<div class="full-width">
					<h3>Key File</h3>
					<tools-files-drop [title]="keyFileName" (droppedFilesEvent)="droppedKeyFilesEvent($event)"
						formats=".pem, .key">
					</tools-files-drop>
				</div>
			</mat-grid-tile>

		</mat-grid-list>

		<!--div>
		<mat-form-field class="mid-width">
			<mat-label>Certificate Password</mat-label>
			<input matInput [type]="hide ? 'password' : 'text'" formControlName="certificatePassword" class="full-width">
			<mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}} </mat-icon>
		</mat-form-field>
	</div-->

		<p>
			<mat-checkbox formControlName="tls">Enable TLS</mat-checkbox>
		</p>

		<div align="end">
			<button mat-raised-button color="primary" type="submit" [disabled]="!ahFormGroup.valid">
				Save
			</button>
		</div>
	</form>
</div>

<section style="margin-top: 30px; margin-left: 20px;">
	<h3>Echo Test</h3>

	<button mat-stroked-button color="primary" (click)="echoTest();">Run echo</button>
	<pre>{{echoResponse|json}}</pre>
</section>

<ng-template #loadingTemplate>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>