import { Component, Input, OnInit } from "@angular/core";
import { DeviceInfo, ExportFormat, FileExport, ServiceInfo } from "../../_models";
import { ServiceExport } from "../../_services";

// FileSaver
import * as FileSaver from "file-saver";

@Component({
  selector: 'tool-export',
  templateUrl: './export.html',
  styleUrls: ['./export.scss']
})

export class ExportTool implements OnInit {
  loading: boolean = false;
  exportFormats: ExportFormat[] = [];
  private _dataFrom?: Date;
  private _dataTo?: Date;
  _devices: DeviceInfo[] = [];
  _services?: ServiceInfo[] = [];

  @Input() set dataFrom(value: Date | undefined) {
    console.warn("dataFrom" + value);
    this._dataFrom = value;
  }

  @Input() set dataTo(value: Date | undefined) {
    console.warn("dataTo" + value);
    this._dataTo = value;
  }

  @Input() set devices(value: DeviceInfo[]) {
    this._devices = value;
  }

  @Input() set services(value: ServiceInfo[] | undefined) {
    this._services = value;
  }

  constructor(
    private exportService: ServiceExport
  ) { }

  ngOnInit(): void {
    this.loadFormats();
  }

  private loadFormats(): void {
    this.loading = true;
    this.exportService.getFormats().subscribe({
      next: (data: ExportFormat[]) => {
        this.exportFormats = data;
      },
      error: error => {
        this.exportFormats = [
          {
            format: "png",
            text: "as PNG"
          },
          {
            format: "jpg",
            text: "as JPG"
          },
          {
            format: "server",
            text: "The server is not running export API"
          }
        ];
      }
    }).add(() => { this.loading = false; });
  }

  export(format: string): void {
    this.exportService.export(format, this._dataFrom, this._dataTo, this._devices, this._services).subscribe(

      (data: FileExport[]) => {
        data.forEach(
          file => {
            let blob: Blob;
            const byteCharacters = atob(file.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            blob = new Blob([byteArray], { type: file.type });
            FileSaver.saveAs(blob, file.name);
          }
        );

      }
    );
  }

}
