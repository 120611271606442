import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Token } from "../_models";
import { ServiceAuth } from "../_services";

@Injectable()
export class InterceptorJWT implements HttpInterceptor {
  private className: string = "InterceptorJWT";
  constructor(
    private auth: ServiceAuth
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: Token | null = this.auth.getLocalUserToken();
    //console.warn("Bearer "+JSON.stringify(token));
    if (token !== null) {
      //console.warn("Bearer "+token.value);
      req = req.clone({ setHeaders: { Authorization: "Bearer "+token.value, 'Access-Control-Allow-Origin': '*' } });
    }
    return next.handle(req);
  }
}