import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmployeesList } from "../_models";

@Injectable()

export class ServiceEmployee {

  constructor(
    private http: HttpClient,
  ) { }

  getEmployees(): Observable<EmployeesList> {
    return this.http.get<EmployeesList>('/api/admin/employees');
  }
}