import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Info } from "../_models";

@Injectable()

export class ServiceInfo {

  constructor(
    private http: HttpClient,
  ) { }

  getBasic(): Observable<Info> {
    return this.http.get<Info>('/api/info');
  }

}