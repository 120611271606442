import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {animate, state, style, transition, trigger} from '@angular/animations';
//import { DataFileInfo, DataFileList } from "src/app/common/_models";
//import { ServiceData, ServiceInfo } from "src/app/common/_services";
import { BottomSheetData } from "../../bottomsheets";

@Component({
  selector: 'reports-list',
  templateUrl: './reports.list.html',
  styleUrls: ['./reports.list.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ListReports implements OnInit, OnDestroy {
  loading: boolean = false;
  
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    //this.dataFiles.sort = sort;
  }

  constructor(
    private bottomSheet: MatBottomSheet,
    //private reportService: ServiceReport
  ) {
  }

  ngOnInit() {
    this.refresh();
  }
  
  ngOnDestroy() { }


  refresh(): void {
    this.loading = true;
  }

  applyFilter(event: any) {
    //this.filterValue = (typeof event === 'object') ? event.target.value : "";
    //this.dataFiles.filter = this.filterValue;
  }


  // ---- FUNCTIONS

}
