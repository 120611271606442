<div class="container-deviceMgt">
  <mat-form-field class="half-width">
    <mat-label>Device</mat-label>
    <mat-select (selectionChange)="onChange($event)" [(ngModel)]="selected">
      <mat-option *ngFor="let dev of devices" [value]="dev">{{dev.bn}}</mat-option>
    </mat-select>
  </mat-form-field>

  <form [formGroup]="deviceFormGroup" (submit)="save()">
    <ng-template matStepLabel>Enter your password</ng-template>
    <div class="login-fields">
      <div>
        <mat-form-field class="half-width">
          <mat-label>Bn</mat-label>
          <input matInput formControlName="bn" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>UUID</mat-label>
          <input matInput formControlName="uuid" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Alias</mat-label>
          <input matInput formControlName="alias">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>User name</mat-label>
          <input matInput formControlName="userName" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}} </mat-icon>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Cliend Id</mat-label>
          <input matInput formControlName="clientId" required>
        </mat-form-field>
      </div>
      <div formGroupName="position" class="half-width">
        <div>
          <mat-form-field class="half-width">
            <mat-label>Latitud</mat-label>
            <input matInput type="number" min="-90" max="90" formControlName="lat" required>
          </mat-form-field>
          <mat-form-field class="half-width">
            <mat-label>Longitud</mat-label>
            <input matInput type="number" min="-180" max="180" formControlName="lon" required>
          </mat-form-field>
        </div>
      </div>


      <div>
        <!--        <mat-form-field class="half-width">
         <mat-label>Enabled</mat-label>
          <input matInput formControlName="clientId" required>-->
        <mat-checkbox formControlName="enabled" color="primary">Enabled</mat-checkbox>
        <!--        </mat-form-field>-->
      </div>


      <div formGroupName="protocols">
        <mat-checkbox formControlName="http" color="primary" class="protocol">HTTP</mat-checkbox>
        <mat-checkbox formControlName="mqtt" color="primary" class="protocol">MQTT</mat-checkbox>
        <mat-checkbox formControlName="spectrum" color="primary" class="protocol">Spectrum</mat-checkbox>
      </div>
    </div>

    <div align="end">
      <button mat-raised-button type="button" color="warn" (click)="delete()" [disabled]="isNew">Delete </button>
      <button mat-raised-button type="button" (click)="new()">New </button>
      <button mat-raised-button type="submit" color="primary" [disabled]="!deviceFormGroup.valid">Save </button>
      <button mat-raised-button type="button" (click)="copyclipboard()">Copy </button>
      <!-- copy JSON object to clipboard -->
    </div>

  </form>

  <div>Here users can add, delete and manage devices.
  </div>
</div>
