import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from "../_models";
import { Md5 } from "ts-md5";

@Injectable()

export class ServiceUsers {

  constructor(
    private http: HttpClient,
  ) { }


  getUsers(): Observable<User[]> {
    return this.http.get<User[]>('/api/root/users');
  }

  createNewUser(user:User):Observable<any> {
    if (user.password) user.password = this.hash(user.password);
    return this.http.post<any[]>('/api/root/users', user);
  }

  updateUser(user:User):Observable<any> {
    return this.http.put<any[]>('/api/root/users', user);
  }

  deleteUser(user:User):Observable<any> {
    return this.http.delete<any[]>('/api/root/users/'+user.id);
  }

  private hash(toHash: string): string {
    return "" + Md5.hashStr(toHash);
  }
}
