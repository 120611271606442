<!--pre>{{ devicesList | json }}</pre-->

<div>
  <div class="row">
    <div class="mat-elevation-z8">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter" [(ngModel)]="filterValue"
          [disabled]="loading">
      </mat-form-field>
      <div class="button-row">
        <button mat-stroked-button class="menu-button" (click)="refresh()">
          <mat-icon>refresh</mat-icon><span>Refresh</span>
        </button>
        <button mat-stroked-button class="menu-button" (click)="getData(selection.selected)"
          [disabled]="selection.selected.length == 0">
          <mat-icon>show_chart</mat-icon><span>Data</span>
        </button>
        <!--button mat-stroked-button class="menu-button" (click)="getGraphs(selection.selected)"
          [disabled]="selection.selected.length == 0">
          <mat-icon>show_chart</mat-icon><span>Graphs</span>
        </button>

        <tool-data-export [devices]="selection.selected" [disabled]="selection.selected.length == 0"></tool-data-export>

        <button mat-icon-button class="button-action" color="primary" *ngIf="selection.selected.length === 1"
          (click)="deviceEdit(selection.selected[0]);">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button class="button-action" color="warn" *ngIf="selection.selected.length !== 0 && imRoot"
          (click)="devicesRemove(selection.selected);">
          <mat-icon>delete_sweep</mat-icon>
        </button-->

      </div>
    </div>
    <mat-paginator class="paginator" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
      (page)="handlePage($event)">
    </mat-paginator>
  </div>
</div>

<div class="basic-container">
  <div *ngIf="!loading; else loadingTemplate;">
    <table mat-table [dataSource]="dataDevices" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- mac Column -->
      <ng-container matColumnDef="mac">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> MAC </th>
        <td mat-cell *matCellDef="let device"> {{ device.mac }} </td>
      </ng-container>


      <!-- bn Column -->
      <ng-container matColumnDef="bn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Base Name </th>
        <td mat-cell *matCellDef="let device" (dblclick)="deviceEdit(device);" class="noselect">
          <span>{{ device.bn }}</span>
        </td>
      </ng-container>


      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let device" class="noselect">
          <span>{{ device.status }}</span>
        </td>
      </ng-container>


      <!-- lastData Column -->
      <ng-container matColumnDef="lastData">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Message </th>
        <td mat-cell *matCellDef="let device" class="noselect">
          <span>{{ device.lastData | date:'yyyy-MM-dd HH:mm:ss' }}</span>
        </td>
      </ng-container>


      <!-- signals Column -->
      <ng-container matColumnDef="signals">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Signal(s) </th>
        <td mat-cell *matCellDef="let device" class="noselect">
          <span>{{ device.signals }}</span>
        </td>
      </ng-container>


      <!-- msgCount Column -->
      <ng-container matColumnDef="msgCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Msg count </th>
        <td mat-cell *matCellDef="let device" class="noselect">
          <span>{{ device.msgCount }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
</div>


<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>