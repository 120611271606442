import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DeviceInfo, DevicesList } from "src/app/common/_models";
import { ServiceDevice } from "src/app/common/_services";
import { BottomSheetData } from "../../bottomsheets";

@Component({
  selector: 'devices-list',
  templateUrl: './devices.list.html',
  styleUrls: ['./devices.list.scss']
})

export class ListDevices implements OnInit, OnDestroy {
  devicesList?: DevicesList;

  loading: boolean = false;
  filterValue: string = "";
  pageSizeOptions: number[] = [20, 50, 100, 200];
  pageSize: number = this.pageSizeOptions[0];
  page: number = 0;

  displayedColumns: string[] = ['select', 'bn', 'status', 'lastData', 'signals', 'msgCount'/*, 'messages', 'reboots'*/];
  dataDevices: MatTableDataSource<DeviceInfo> = new MatTableDataSource();
  selection = new SelectionModel<DeviceInfo>(true, []);

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataDevices.sort = sort;
  }
  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;

  constructor(
    private bottomSheet: MatBottomSheet,
    private deviceService: ServiceDevice
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy() { }


  refresh(): void {
    this.loading = true;
    this.dataDevices = new MatTableDataSource();
    this.selection = new SelectionModel<DeviceInfo>(true, []);

    this.deviceService.getDevices().subscribe({
      next: data => {
        //console.log(data.devices);
        this.dataDevices = new MatTableDataSource(data.devices);
        this.dataDevices.paginator = this.paginator!;
        this.applyFilter(this.filterValue);
      },
      error: error => {
        console.error("Error: " + JSON.stringify(error), 5000);
      }
    }).add(() => { this.loading = false; });

  }

  applyFilter(event: any) {
    this.filterValue = (typeof event === 'object') ? event.target.value : "";
    this.dataDevices.filter = this.filterValue;
  }

  // ---- Paginator specific
  handlePage(e: any) {
    this.page = e.pageIndex;
    this.pageSize = e.pageSize;
  }

  // ---- TABLE specific
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataDevices.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataDevices.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: DeviceInfo): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row 3`;
  }

  toggleColumn(name: string): void {
    //this.logger.debug(this.className, "addColumn " + name);
    if (this.displayedColumns.includes(name)) {
      const index = this.displayedColumns.indexOf(name, 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    } else {
      this.displayedColumns.push(name);
    }
    // this.logger.debug(this.className, "addColumn " + name + " done!");
  }

  // ---- FUNCTIONS

  deviceEdit(device: DeviceInfo) {
    //console.log("deviceEdit: "+device.bn);
    /*this.logger.debug(this.className, "deviceEdit: " + device.mac);

    let bottomSheetRef = this.bottomSheet.open(BottomSheetDevice, { data: device });
    bottomSheetRef.afterDismissed().subscribe(
      data => {
        this.logger.debug(this.className, "BottomSheetDevice: " + JSON.stringify(data));
      }
    );*/
  }

  getData(devices: DeviceInfo[]) {
    const bottomSheetRef = this.bottomSheet.open(BottomSheetData, { data: devices });
  }

}