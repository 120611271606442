import { Component, OnInit } from "@angular/core";

@Component({
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})

export class StorageComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    //console.log("Connecting");
  }

}
