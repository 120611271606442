// Core Modules
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { ServiceCameras } from '../../_services';


@Component({
  selector: 'tools-image-autorefresh',
  templateUrl: './image-autorefresh.tools.html',
  styleUrls: ['./image-autorefresh.tools.scss']
})


export class ToolsImageAutorefresh implements OnDestroy {

  play: boolean = true;
  playSpeed: number = 1;
  private _uuid?: string;
  private _ch?: number;
  private _width?: number;
  //private _height?: number;
  private mini: boolean = true;
  private timeout?: NodeJS.Timeout;
  source: string = "";
  containerWidth:string = "300px";


  @Input() set uuid(uuid: string) {
    this._uuid = uuid;
  }

  @Input() set ch(ch: number) {
    this._ch = ch;
  }

  @Input() set width(width: number) {
    this._width = width;
  }

  //@Input() set height(height: number) {
  //  this._height = height;
  //}

  constructor(
    private camerasService: ServiceCameras
  ) {
    this.updateTimer();
  }

  private updateTimer(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setInterval(() => { this.loop(); }, 1000 * this.playSpeed);
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private loop(): void {
    if (this.play) {
      if (this._uuid != null) {
        this.camerasService.getCamera(this._uuid, this._ch!).subscribe(
          (data: any) => {
            let reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => {
              this.source = "" + reader.result;
            }
          },
          (error: HttpErrorResponse) => {
            console.warn("error: " + error.status + " " + error.statusText + " | ");
          }
        )

      }
    }
  }


  // Interface
  button_play(): void {
    this.play = !this.play;
  }

  button_speed(): void {
    let ps = 1 + this.playSpeed;
    if (ps > 10) ps = 1;
    this.playSpeed = ps;
    this.updateTimer();
  }

  button_resize():void {
    this.mini = !this.mini;
    if (this.mini) {
      this.containerWidth = "300px";
    } else if (this._width) {
      this.containerWidth = this._width+"px";
    }
  }
}
