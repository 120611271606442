/** This must be moved as a separate module later on */

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ArrowheadConfiguration, File } from "src/app/common/_models";
import { ServiceArrowhead } from "src/app/common/_services";

@Component({
  templateUrl: './arrowhead.component.html',
  styleUrls: ['./arrowhead.component.scss']
})

export class ArrowheadComponent implements OnInit {
  loading: boolean = false;
  hide: boolean = true;
  oldConf?: ArrowheadConfiguration;
  caFileName: string = "Add your CA file";
  certFileName: string = "Add your Cert file";
  keyFileName: string = "Add your Key file";

  ahFormGroup: FormGroup = new FormGroup({
    enabled: new FormControl(false,),
    tls: new FormControl(false,),
    systemName: new FormControl(null, Validators.required),
    serviceRegistry: new FormControl(null, Validators.required),
    //certificatePassword: new FormControl(null, Validators.required),
  });

  echoResponse: any;

  constructor(
    private ahService: ServiceArrowhead
  ) { }

  ngOnInit() {
    // Request previous config
    this.loading = true;
    this.ahService.getConfiguration().subscribe({
      next: data => {
        this.oldConf = data;
        //console.log(JSON.stringify(data));

        this.ahFormGroup.get('enabled')?.setValue(this.oldConf.enabled);
        this.ahFormGroup.get('tls')?.setValue(this.oldConf.tls);
        this.ahFormGroup.get('systemName')?.setValue(this.oldConf.systemName);
        this.ahFormGroup.get('serviceRegistry')?.setValue(this.oldConf.serviceRegistry);


        if (this.oldConf?.ca) {
          this.caFileName = this.oldConf.ca.info.name;
        }
        if (this.oldConf?.cert) {
          this.certFileName = this.oldConf.cert.info.name;
        }
        if (this.oldConf?.key) {
          this.keyFileName = this.oldConf.key.info.name;
        }
      },
      error: error => {
      }
    }).add(() => { this.loading = false });
  }

  saveChanges() {
    this.loading = true;
    let conf: ArrowheadConfiguration = this.ahFormGroup.value;
    conf.ca = this.tmpCA;
    conf.cert = this.tmpCert;
    conf.key = this.tmpKey;

    //console.log("OUT: "+JSON.stringify(conf));
    this.ahService.saveConfiguration(conf).subscribe({
      next: data => {

      },
      error: error => {

      }
    }).add(() => { this.loading = false });
  }

  echoTest() {
    this.ahService.echoTest().subscribe(
      data => {
        this.echoResponse = data;
      },
      error => {
        this.echoResponse = error;
      }
    );
  }

  tmpCA: File = new File();

  droppedCAFilesEvent(newFiles: File[]) {
    newFiles.forEach(file => {
      this.tmpCA = file;
    });
  }


  tmpCert: File = new File();
  droppedCertFilesEvent(newFiles: File[]) {
    newFiles.forEach(file => {
      this.tmpCert = file;
    });
  }

  tmpKey: File = new File();
  droppedKeyFilesEvent(newFiles: File[]) {
    newFiles.forEach(file => {
      this.tmpKey = file;
    });
  }


}
