import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShadowDeviceMgt } from "../_models";
//import { DevicesList } from "../_models";

@Injectable()

export class ServiceShadowDevices {

  constructor(
    private http: HttpClient,
  ) { }


  getDevicesMgt(): Observable<ShadowDeviceMgt[]> {
    return this.http.get<ShadowDeviceMgt[]>('/api/shadow/uuid');
  }

  createNewDevice(device:ShadowDeviceMgt):Observable<any> {
    return this.http.post<any[]>('/api/shadow/uuid', device);
  }

  updateDevice(device:ShadowDeviceMgt):Observable<any> {
    return this.http.put<any[]>('/api/shadow/uuid', device);
  }

  deleteDevice(device:ShadowDeviceMgt):Observable<any> {
    return this.http.delete<any[]>('/api/shadow/uuid/'+device.uuid);
  }
}
