<div class="container">

  <mat-toolbar class="toolbar">
    <div class="title" color="primary">
      <mat-icon class="icon-tab">show_chart</mat-icon>
      Data viewer
    </div>



    <span class="spacer"></span>
    <small>{{isBigDataMode()?'Big Data mode':''}}</small>
    <!--button mat-button (click)="close()" align="end" color="primary">
      <mat-icon>close</mat-icon>Close
    </button-->
  </mat-toolbar>

  <!--pre>{{ devices | json }}</pre>
  <pre>{{ services.value | json }}</pre-->
  <div>

    <div class="row">
      <div class="column-left">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Services</mat-label>
          <mat-select [formControl]="services" multiple (selectionChange)="updatePoints();">
            <mat-option *ngFor="let service of dataInfo?.services" [value]="service">{{service.alias}}
              ({{service.points}} points)</mat-option>
          </mat-select>
          <div *ngIf="!loading; else loadingTemplate;"></div>
        </mat-form-field>



        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Number of datapoints</mat-label>
          <input type="number" matInput placeholder="?" [(ngModel)]="totalPoints" readonly style="text-align: right;"
            [disabled]="loading">
        </mat-form-field>


        <mat-form-field appearance="fill" class="full-width" *ngIf="!isBigDataMode();">
          <mat-label>Time Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate" [disabled]="loading">
            <input matStartDate placeholder="Start date" [(ngModel)]="dataFrom">
            <input matEndDate placeholder="End date" [(ngModel)]="dataTo">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width" *ngIf="isBigDataMode();">
          <mat-label>Select a day</mat-label>
          <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [disabled]="loading"
            [(ngModel)]="dateBD" (dateChange)="updatedCalendarBD();">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>


        <button mat-flat-button class="full-width" (click)="requestGraph()" color="primary" [disabled]="loadingRange"
          [disabled]="loading">
          <mat-icon>insights</mat-icon> Plot <ng-container *ngIf="!loading; else loadingTemplate;"></ng-container>
        </button>

        <button mat-flat-button style="margin-top: 10px;" class="full-width" (click)="requestFFT();" color="primary"
          [disabled]="loadingRange" [disabled]="loading" *ngIf="checkIfFFT();">
          <mat-icon>insights</mat-icon> FFT <ng-container *ngIf="!loading; else loadingTemplate;"></ng-container>
        </button>

        <div style="margin-bottom: 20px;"></div>

        <tool-export [dataFrom]="dataFrom" [dataTo]="dataTo" [devices]="devices" [services]="services.value"
          *ngIf="!isBigDataMode();">
        </tool-export>

        <div style="margin-bottom: 20px;"></div>

        <tool-export-big-data [dataFrom]="dateTimeBigData" [devices]="devices" [services]="services.value" *ngIf="isBigDataMode();"></tool-export-big-data>

        <div style="margin-bottom: 20px;"></div>
        <!--button mat-flat-button class="full-width" (click)="close()" color="warn" style="margin-top: 20px;">
          <mat-icon>close</mat-icon>Close
        </button-->

      </div>
      <div class="column-right">

        <mat-toolbar class="graph-toolbar">
          <button mat-stroked-button *ngIf="!loadingRange" [matMenuTriggerFor]="toolsMenu">
            <mat-icon>construction</mat-icon> Tools <mat-icon>expand_more</mat-icon>
          </button>

          <mat-menu #toolsMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="toolsMaxMinMenu">Max/Min</button>
            <mat-divider></mat-divider>
            <button mat-menu-item disabled>...</button>
          </mat-menu>

          <mat-menu #toolsMaxMinMenu="matMenu">
            <form (click)="$event.stopPropagation()">
              <mat-form-field>
                <mat-label>Min</mat-label>
                <input matInput type="number" (keyup)="applyYScaleMin($event)">
              </mat-form-field>
              <mat-divider></mat-divider>
              <mat-form-field>
                <mat-label>Max</mat-label>
                <input matInput type="number" (keyup)="applyYScaleMax($event)">
              </mat-form-field>
            </form>

          </mat-menu>


          <button mat-stroked-button *ngIf="!loadingRange && showFFT" [matMenuTriggerFor]="downloadData">
            <mat-icon>download</mat-icon> Download data <mat-icon>expand_more</mat-icon>
          </button>



          <mat-menu #downloadData="matMenu">
            <button mat-menu-item (click)="fftDownloadCSV();">CSV</button>
          </mat-menu>

        </mat-toolbar>

        <div class="content" #graphArea>

          <div class="graph" *ngIf="!loadedGraph">
            <div *ngIf="!loadingRange; else loadingRotTemplate;"></div>
            <img src="assets/img/ThingWaveLogo.png" class="logo">
          </div>
          <div class="graph" *ngIf="loadedGraph" class="full-area">
            <div *ngIf="!showFFT" [chart]="stockChart" class="full-area"></div>
            <div *ngIf="showFFT" class="full-area">
              <fft-graph [height]="graphHeight" [width]="graphWidth" [data]="fftData" [export]="fftExportConfig">
              </fft-graph>
            </div>
          </div>

        </div>

        <tool-time-day-slider (newDayTime)="newDayTime($event);" [disabled]="loadingData"></tool-time-day-slider>

      </div>

    </div>



  </div>
</div>


<ng-template #loadingRotTemplate>
  <mat-spinner mode="indeterminate" diameter=40 strokeWidth=3></mat-spinner>
</ng-template>

<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>

<ng-template #logoBlock>
  <img src="assets/img/ThingWaveLogo.png" class="logo">
</ng-template>