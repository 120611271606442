const FFT = require('fft.js');

export class FftEngine {
  private NFFT: number;
  private f: number[];
  private fft: any;
  private fftOut: number[];

  constructor(
    private fsHz: number,
    private bTimeMillis: number,

  ) {
    console.warn("NEW FFT Engine");
    this.NFFT = this.nextpow2(fsHz);
    this.f = this.generateF();
    //console.log("f: "+JSON.stringify(this.f));
    this.fft = new FFT(this.NFFT);
    this.fftOut = this.fft.createComplexArray();
    //console.log("Len: " + this.fftOut.length);
    //console.log(JSON.stringify(this.fftOut));

    console.warn(
      "FFT -- Configuration\n" +
      "\tFs: " + fsHz + "Hz\n",
      "\tBinning: " + bTimeMillis + "ms\n",
      "\tNFFT: " + this.NFFT + "\n",
    );
  }


  // ----
  public getF(): number[] {
    //console.log("r: "+JSON.stringify(this.f));
    return this.f;
  }

  public process(data: number[]): number[][] {
    console.warn("Processing.... " + data.length + " points");
    let result: number[][] = [];

    let chunks = Math.ceil(data.length / this.bTimeMillis);

    for (let c = 0; c < chunks; c++) {
      let dat: number[] = data.slice(c * this.bTimeMillis, (c == chunks - 1) ? data.length : (c + 1) * this.bTimeMillis);


      //console.log("Datlen: " + dat.length);

      this.fft.transform(this.fftOut, this.fft.toComplexArray(dat));
      // fft/L
      for (let i = 0; i < this.fftOut.length; i++) {
        this.fftOut[i] /= this.NFFT;
      }

      //console.log(JSON.stringify(this.fftOut));
      //console.log(this.complexArray2string(this.fftOut));

      let abs = this.complexABS(this.fftOut);
      for (let i = 0; i < abs.length; i++) {
        abs[i] *= 2;
      }
      result.push(abs);
    }

    return result;
  }

  // ----
  private nextpow2(l: number): number {
    return 1 << 32 - Math.clz32(l);
  }

  private generateF(): number[] {
    let len = this.NFFT / 2 + 1;
    //console.log("len: " + len);
    let f = new Array(len);
    for (let i = 0; i < len; i++) {
      f[i] = i * (1 / (len - 1)) * this.fsHz / 2;
    }
    //console.log(JSON.stringify(f));

    return f;
  }

  private complexArray2string(array: number[]): string {
    let txt = "Size :" + array.length / 2 + "\n";
    for (let i = 0; i < array.length; i += 2) {
      txt += array[i] + "  " + array[i + 1] + "i\n";
    }
    return txt;
  }

  private complexABS(array: number[]): number[] {
    let len = array.length;
    let abs = new Array(len / 2);
    for (let i = 0; i < len; i += 2) {
      abs[i / 2] = Math.sqrt(Math.pow(array[i], 2) + Math.pow(array[i + 1], 2));
    }

    return abs;
  }
}