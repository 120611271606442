<h2>ThingWave Nucleus API Documentation</h2>

<b>1. Protocols and Endpoints</b><br>
&nbsp;UUIDs [1] are used to uniquely identify a device's data enpoints. UUIDs are of this format: 123e4567-e89b-12d3-a456-426614174000<br>

<br><b>1.1 MQTT</b><br>
&nbsp;MQTT in Nucleus use three different topics depending on the type of data. To store data, simply send a PUBLISH message with either IPSO [3] or GeoJSON [2] encoded CBOR [5] or JSON payloads.

<ul>
  <li><b>data/ipso/$UUID$</b>: This topic is used for ingesting IPSO / LwM2M compliant messages</li>
  <li><b>data/geojson/$UUID$</b>: This topic is used for ingesting GeoJSON messages</li>
</ul>

&nbsp;MQTT requires the use of ClientID, Username and Password to connect a device to the Nucleus MQTT Broker.<br>

<br><b>1.2 HTTP</b><br>
&nbsp;MQTT in Nucleus use three different endpoints depending on the type of data, To store data, simply send a PUT request with either IPSO encoded CBOR [5] or JSON payloads, or GeoJSON encoded data using JSON.

<ul>
  <li><b>/data/ipso/$UUID$</b>: This endpoint is used for ingesting IPSO / LwM2M compliant messages</li>
  <li><b>/data/geojson/$UUID$</b>: This endpoint is used for ingesting GeoJSON messages</li>
  <li><b>/data/media/$UUID$</b>: This endpoint is used for ingesting pictures</li>
</ul>

&nbsp;HTTP requires the use of Username and Password to connect a device to the Nucleus HTTP server<br>. An Authorization header must be added to each request in the form of:<br>
<i>Authorization: Basic username:password</i> (where the username:password text is Base64 encoded).<br>
The proper Content-type header must also be used: application/json for JSON or application/cbor for CBOR.<br>

<br><b>1.3 WebSockets</b><br>
&nbsp;WebSockets (WS) in Nucleus use three different endpoints, depending on the type of data.

<ul>
  <li><b>/ws/data/ipso/$UUID$</b>: This endpoint is used for ingesting IPSO / LwM2M compliant messages</li>
  <li><b>/ws/data/geojson/$UUID$</b>: This endpoint is used for ingesting GeoJSON messages</li>
</ul>

WebSockets requires the use of Username and Password to connect a device to the Nucleus HTTP+WS server.<br>An Authorization header must be added to the connect request in the form of:<br>
<i>Authorization: Basic username:password</i> (where the username:password text is Base64 encoded).<br>

<br><b>1.4 SPECTRUM</b><br>
Spectrum support is currently experimental and is not to be used.<br>

<br><b>2. Datamodels</b><br>

<br><b>2.1 IPSO Smart Objects</b><br>
Nucleus only supports the use of IPSO Smart Objects and OMA LwM2M data models for sensor data.<br>

<code>
</code>

<table>
<tr>
<td>URN/Version</td><td>XML Name</td><td>Object Name</td><td>Description</td>
</tr>

<tr>
<td>urn:oma:lwm2m:ext:8314</td><td>8314</td><td>Accelerometer</td><td>
  5801: ODR (number)<br>
  5802: GravityRemoved (bool)<br>
  5803: DownSample (number)<br>
  5701: MAX (number)<br>
  5702: MIN (number)<br>
  5703: AVG (number)<br>
  5711: RMS (number)<br>
</td>
</tr>

</table>

<br><b>2.2 GeoJSON</b><br>
Nucleus currently only support GeoJSON for map based data such as GPS coordinates.<br>

<code>

</code>

<br><b>3. References</b><br>
&nbsp;[1]: UUID <a href="https://datatracker.ietf.org/doc/html/rfc4122">RFC 4122</a><br>
&nbsp;[2]: GeoJSON specification. <a href="https://geojson.org/">GeoJSON</a><br>
&nbsp;[3]: IPSO Smart Objects <a href="https://omaspecworks.org/develop-with-oma-specworks/ipso-smart-objects/">OMA SpecWorks</a><br>
&nbsp;[4]: OMA LwM2M <a href="https://technical.openmobilealliance.org/OMNA/LwM2M/LwM2MRegistry.html">OMA LwM2M</a><br>
&nbsp;[5]: CBOR specification. <a href="https://datatracker.ietf.org/doc/html/rfc8949">RFC 8949</a><br>