<div class="container-company box even">
  <div class="halfish-width flex">
    <mat-form-field class="half-width" *ngIf="user?.role === 'root'">
      <mat-label>Company</mat-label>
      <mat-select (selectionChange)="onChange($event)" [(ngModel)]="selected">
        <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <form [formGroup]="companyFormGroup" (submit)="save()">
      <ng-template matStepLabel>Enter your password</ng-template>
      <div class="login-fields">
        <div>
          <mat-form-field class="form-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Domain name</mat-label>
            <input matInput formControlName="domainName">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Company type</mat-label>
            <input matInput formControlName="type" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Country</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of countries" [value]="country">{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Postal code</mat-label>
            <input matInput formControlName="postalCode" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Contact person</mat-label>
            <input matInput formControlName="contactPerson" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Contact Email</mat-label>
            <input matInput formControlName="contactEmail" required>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox formControlName="consult" color="primary">Consult</mat-checkbox>
        </div>
      </div>

      <div align="end" class="control-buttons">
        <button mat-raised-button type="button" color="warn" (click)="delete()" [disabled]="isNew">Delete </button>
        <button mat-raised-button type="button" (click)="new()">New </button>
        <button mat-raised-button type="submit" color="primary" [disabled]="!companyFormGroup.valid">Save </button>
        <button mat-raised-button type="button" (click)="copyclipboard()">Copy </button> <!-- copy JSON object to clipboard -->
      </div>
    </form>
  </div>


  <div class="halfish-width flex">
    <div class="box column-box">
      <div class="flex halfish-height">
        <div>
          <h2>Consults</h2>
        </div>
        <div *ngIf="selected else noneconsults">
          Selected companies have access to the data of <b>{{ selected.name }}</b>.
        </div>
        <ng-template #noneconsults>
          <div>
            Selected companies have access to the data of <b>[Company]</b>.
          </div>
        </ng-template>
        <consults-list [company]="selected"></consults-list>
      </div>

      <div class="flex halfish-height">
        <div>
          <h2>Consultees</h2>
        </div>
        <div *ngIf="selected else noneconsultees">
          <b>{{ selected.name }}</b> have access to the data of these companies.
        </div>
        <ng-template #noneconsultees>
          <div>
            <b>[Company]</b> have access to the data of these companies.
          </div>
        </ng-template>
        <consultees-list [company]="selected"></consultees-list>
      </div>
    </div>
  </div>
</div>