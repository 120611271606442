import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceInfo, ExportFormat, FileExport, ServiceInfo } from "../_models";

@Injectable()

export class ServiceExport {

  constructor(
    private http: HttpClient,
  ) { }

  getFormats(): Observable<ExportFormat[]> {
    return this.http.get<ExportFormat[]>('/api/data/export/info');
  }

  export(format: string, from: Date | undefined, to: Date | undefined, devices: DeviceInfo[], services: ServiceInfo[] | undefined): Observable<FileExport[]> {
    let url: string = '/api/data/export?'
    url += 'format=' + format;
    url += '&ids=' + this.getDevicesIds(devices);
    from = from;
    to = to;
    if (services != undefined) url += '&services=' + this.getServicesIds(services);
    if (from != undefined) url += '&from=' + from.getTime();
    if (to != undefined) url += '&to=' + to.getTime();
    console.warn(url);
    return this.http.get<FileExport[]>(url);
  }

  private getDevicesIds(devices: DeviceInfo[]): string {
    return Array.prototype.map.call(devices, d => d.bn).toString();
  }

  private getServicesIds(services: ServiceInfo[]): string {
    return Array.prototype.map.call(services, s => s.id).toString();
  }
}