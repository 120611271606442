import { Component, Input } from "@angular/core";
import FileSaver from "file-saver";
import { FftEngine } from "src/app/common/_libs";
import { DataSource } from "src/app/common/_models";

const FFT = require('fft.js');

export interface FftExportConfig {
  id: string,
  time: Date,
  format: string,
}

@Component({
  selector: 'fft-graph',
  templateUrl: './fft.graph.html',
  styleUrls: ['./fft.graph.scss']
})


export class FftGraph {


  @Input() set height(value: number) {
    this.layout.height = value;
  }

  @Input() set width(value: number) {
    this.layout.width = value;
  }

  @Input() set data(value: DataSource[]) {
    this.plotNewData(value);
  }

  @Input() set export(value: FftExportConfig | undefined) {
    if (value != undefined)
      this.exportData(value);
  }



  /*private x: number[] = [1, 2, 3];
  private y: any[] = [1, 2];
  private z: number[][] = [[0, 0, 0], [1, 1, 1]];
*/

  private colorscales: string[] = ['Rainbow', 'Bluered', 'Earth', 'Picnic'/*,'Greens,Greys,Hot,Jet,Picnic,Portland,Rainbow,RdBu,Reds,Viridis,YlGnBu,YlOrRd*/];
  public layout = {
    height: 0,
    width: 0,
    title: 'FFT'
  };

  public _data: any = [];

  /*private dataFormat =
    {
      x: this.x,
      y: this.y,
      z: this.z,
      type: 'surface',
      contours: {
        z: {
          show: true,
          usecolormap: true,
          highlightcolor: "#42f462",
          project: { z: true }
        }
      }
    };*/

  private plotNewData(dss: DataSource[]): void {
    this._data = [];
    dss.forEach(ds => {
      console.log("DataSource: " + ds.service.id);
      let data: number[][] = ds.data;
      console.warn("Data length: " + data.length);
      let dat: number[] = new Array(data.length);

      for (let i = 0; i < data.length; i++) {
        dat[i] = data[i][1];
      }

      let fs = 1000;
      let binningtime = 60000;
      const fftEngine: FftEngine = new FftEngine(fs, binningtime);
      let f: number[] = fftEngine.getF();
      //console.log(JSON.stringify(f));
      let result: number[][] = fftEngine.process(dat);

      let x: number[] = f.slice(1, f.length);
      let y: any[] = [];
      let z: number[][] = [];

      let time = data[0][0];


      for (let i = 0; i < result.length; i++) {
        y.push(new Date(time + i * binningtime).toTimeString().split(' ')[0]);
        z.push(result[i].slice(1, f.length));
      }
      console.warn("len: " + this._data.length);
      //console.warn("color: " + this.hcolors[this._data.length]);


      this._data.push({
        name: ds.service.alias,
        x: x,
        y: y,
        z: z,
        type: 'surface',
        contours: {
          z: {
            //show: true,
            //usecolormap: true,
            //highlightcolor: this.hcolors[this._data.length],
            project: { z: true }
          }
        },
        colorscale: this.colorscales[this._data.length],
        showscale: false,
        showlegend: true,
      });


      //this.updateChart();

      //console.log(JSON.stringify(this.x));
      //console.log(JSON.stringify(this.y));
      //console.log(JSON.stringify(this.z));
    });

  }



  /*private updateChart(): void {
    this._data.x = this.x.slice();
    this._data.y = this.y.slice();
    this._data.z = this.z.slice();
  }*/

  private exportData(config: FftExportConfig): void {
    if (config == null) return;
    if (config.format == "") return;
    switch (config.format) {
      case "CSV":
        this._data.forEach((d: any) => {
          console.log("Name:" + d.name);
          let content: string = "time," + d.x.join(' Hz,') + " Hz\n";

          let date = new Date(config.time);
          for (let i = 0; i < d.y.length; i++) {
            content += date.getTime() + "," + d.z[i].join(',') + "\n";
            date.setTime(date.getTime()+60000);
          }

          let blob: Blob = new Blob([content], { type: "text/plain" });
          FileSaver.saveAs(blob, d.name + "-" + d.y[0] + ".csv");

        });

        break;
      default:
        console.error("Unsupported exporting format: " + config.format);
        break;
    }
  }
}

