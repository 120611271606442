<!--pre>{{ filesList | json }}</pre-->

<div>
  <div class="row">
    <div class="mat-elevation-z8">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter" [(ngModel)]="filterValue"
          [disabled]="loading">
      </mat-form-field>
      <div class="button-row">
        <button mat-stroked-button class="menu-button" (click)="refresh()">
          <mat-icon>refresh</mat-icon><span>Refresh</span>
        </button>
        <!--button mat-stroked-button class="menu-button" (click)="getGraphs(selection.selected)"
          [disabled]="selection.selected.length == 0">
          <mat-icon>show_chart</mat-icon><span>Graphs</span>
        </button>

        <tool-data-export [devices]="selection.selected" [disabled]="selection.selected.length == 0"></tool-data-export>

        <button mat-icon-button class="button-action" color="primary" *ngIf="selection.selected.length === 1"
          (click)="deviceEdit(selection.selected[0]);">
          <mat-icon>edit</mat-icon>
        </button>

        <button mat-icon-button class="button-action" color="warn" *ngIf="selection.selected.length !== 0 && imRoot"
          (click)="devicesRemove(selection.selected);">
          <mat-icon>delete_sweep</mat-icon>
        </button-->

      </div>
    </div>
  </div>
</div>

<div class="basic-container">
  <div *ngIf="!loading; else loadingTemplate;">
    <table mat-table [dataSource]="dataFiles" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" color="primary">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.name != '..'">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" color="primary">
            </mat-checkbox>
          </ng-container>
        </td>
      </ng-container>


      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let file" (dblclick)="fileEdit(file);" class="noselect">
          <span>
            <mat-icon style="vertical-align:middle;">{{ file.dir? 'folder':'description'}}</mat-icon> {{ file.name }}
          </span>
        </td>
      </ng-container>

      <!-- size Column -->
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
        <td mat-cell *matCellDef="let file" class="noselect">
          <span>{{ file.size }}</span>
        </td>
      </ng-container>

      <!-- Last Modified Column -->
      <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Modified </th>
        <td mat-cell *matCellDef="let file" class="noselect">
          <span>{{ file.lastModified | date:'MMMM d, y, h:mm:ss (z)' }}</span>
        </td>
      </ng-container>

      <!-- OLD Download: delete 
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef justify="end"> Download </th>
        <td mat-cell *matCellDef="let file" class="noselect">
          <ng-container *ngIf="file.dir == false">
            <a href="api/data/storage/download{{ path }}/{{ file.name }}">
              <mat-icon>file_download</mat-icon>
            </a>
          </ng-container>
        </td>
      </ng-container>
-->


      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef justify="end"> Download </th>
        <td mat-cell *matCellDef="let file" class="noselect">
          <ng-container *ngIf="file.dir == false">
            <button mat-icon-button color="primary" aria-label="Download your file" (click)="download(path, file.name);">
              <mat-icon>file_download</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>

      <!-- status Column -->
      <!-- <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let file" class="noselect">
          <span>{{ device.status }}</span>
        </td>
      </ng-container> -->


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row (click)="fileClicked(row)" *matRowDef="let row; columns: displayedColumns;" class="file-row"></tr>
    </table>
  </div>
</div>


<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>