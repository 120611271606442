<mat-card class="login-card">
  <img mat-card-image src="assets/img/ThingWaveLogo.png" class="login-image">
  <mat-card-header>
    <div mat-card-avatar class="login-header-image"></div>
    <mat-card-title>
      <p>Nucleus v {{ info?.version }} ({{ info?.build }}) </p>
    </mat-card-title>
    <mat-card-subtitle>
      <p class="message">{{ message }}</p>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="userFormGroup" (submit)="login()">
      <ng-template matStepLabel>Enter your password</ng-template>
      <div class="login-fields">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}} </mat-icon>
        </mat-form-field>
      </div>
      <div align="end">
        <button mat-raised-button color="primary" type="submit" [disabled]="!userFormGroup.valid ">
          {{userFormGroup.valid? 'Log In':'Fill email and password'}}
        </button>
      </div>
      <!--div class="forgot-pass" *ngIf="enabledSec; else loading;">
                <button mat-button color="primary" (click)="forgotPassword()">forgot password?</button>
                <a [routerLink]="" (click)="forgotPassword()">forgot password?</a>
            </div-->
    </form>
  </mat-card-content>
</mat-card>

<div class="footer">
  <a href="https://www.thingwave.eu" target="_blank">ThingWave</a> Nucleus&trade;
</div>