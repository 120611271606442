import { SelectionModel } from "@angular/cdk/collections";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Company, CompanyList, CompanyRelations } from "src/app/common/_models";
import { ServiceCompanies } from "src/app/common/_services";

@Component({
  selector: 'consultees-list',
  templateUrl: './consultees.list.html',
  styleUrls: ['./consultees.list.scss']
})

export class ListConsultees implements OnInit, OnDestroy {
  @Input() company: Company | undefined;
  consulteeList?: CompanyList;

  loading: boolean = false;
  displayedColumns: string[] = ['select', 'id', 'name', 'domain'];
  dataConsultees: MatTableDataSource<Company> = new MatTableDataSource();
  selection = new SelectionModel<Company>(true, []);
  original: Company[] = [];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataConsultees.sort = sort;
  }

  constructor(
    private companiesService: ServiceCompanies
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh(): void {
    this.loading = true;
    this.dataConsultees = new MatTableDataSource();

    this.companiesService.getCompaniesMinimal().subscribe({
      next: data => {
        if (this.company) {
          const index = data.findIndex((e) => e.id === this.company?.id)
          if (index > -1) {
            data.splice(index, 1);
          }
        }
        this.dataConsultees = new MatTableDataSource(data);
      },
      error: error => {
        console.error("Error: " + JSON.stringify(error), 5000);
      }
    }).add(() => { this.loading = false; });

    if (this.company) {
      this.companiesService.getConsultees(this.company).subscribe({
        next: data => {
          // We can't pass data directly to SelectionModel
          // https://stackoverflow.com/questions/52173675/how-do-you-add-an-initial-selection-for-angular-material-table-selectionmodel
          // Instead we filter the datasource based on the ids in data to get a shallow copy and pass that on. 
          const res = this.dataConsultees.data.filter(e1 => data.map(e2 => e2.id).includes(e1.id));
          this.selection = new SelectionModel<Company>(true, res);
          this.original = res;
        }
      })
    } else {
      this.selection = new SelectionModel<Company>(true, []);
      this.original = [];
    }

    this.loading = false;
  }

  checkboxLabel(row: Company): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row 3`;
  }

  saveConsultees(): void {
    if (this.company) {
      let relations = {} as CompanyRelations;
      relations.companyId = this.company.id;
      relations.relations = [];
      for (let i = 0; i < this.selection.selected.length; i++) {
        relations.relations.push({
          consultId: this.company.id,
          consulteeId: this.selection.selected[i].id
        })
      }

      this.companiesService.updateCompanyConsultees(relations).subscribe({
        next: data => {

        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      })
    }
  }

  restoreSelection(): void {
    this.selection = new SelectionModel<Company>(true, this.original);
  }

  clearConsultees(): void {
    this.selection.clear();
  }
}