<div class="basic-container">
  <div *ngIf="!loading; else loadingTemplate;">
    <table mat-table [dataSource]="dataConsultees" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" color="primary">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell *matCellDef="let consultee"> {{ consultee.id }} </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let consultee"> {{ consultee.name }} </td>
      </ng-container>

      <!-- domain Column -->
      <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Domain </th>
        <td mat-cell *matCellDef="let consultee"><a href="{{consultee.domainName}}" target="_blank"> {{ consultee.domainName }} </a></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div align="end" class="control-buttons">
      <button mat-raised-button type="button" color="primary" (click)="saveConsultees()" [disabled]="selection.selected.toString() === original.toString()">Save</button>
      <button mat-raised-button type="button" (click)="restoreSelection()" [disabled]="selection.selected.toString() === original.toString()">Restore</button>
      <button mat-raised-button type="button" color="warn" (click)="clearConsultees()" [disabled]="selection.isEmpty()">Clear</button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>