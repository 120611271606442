import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Employee, EmployeesList } from "src/app/common/_models";
import { ServiceEmployee } from "src/app/common/_services";

@Component({
  selector: 'employees-list',
  templateUrl: './employees.list.html',
  styleUrls: ['./employees.list.scss']
})

export class ListEmployees implements OnInit, OnDestroy {
  employeesList?: EmployeesList;

  loading: boolean = false;
  displayedColumns: string[] = ['select', 'name', 'email', 'role'];
  dataEmployees: MatTableDataSource<Employee> = new MatTableDataSource();
  selection = new SelectionModel<Employee>(true, []);

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataEmployees.sort = sort;
  }

  constructor(
    private employeeService: ServiceEmployee
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  ngOnDestroy(): void { }

  refresh(): void {
    this.loading = true;
    this.dataEmployees = new MatTableDataSource();
    this.selection = new SelectionModel<Employee>(true, []);

    this.employeeService.getEmployees().subscribe({
      next: data => {
        console.log(data.employees);
        this.dataEmployees = new MatTableDataSource(data.employees);
      },
      error: error => {
        console.error("Error: " + JSON.stringify(error), 5000);
      }
    }).add(() => { this.loading = false; });

  }

  // ---- TABLE specific
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataEmployees.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataEmployees.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Employee): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row 3`;
  }
}