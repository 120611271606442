import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSliderChange } from "@angular/material/slider";

@Component({
  selector: 'tool-time-day-slider',
  templateUrl: './time-day-slider.tool.html',
  styleUrls: ['./time-day-slider.tool.scss']
})

export class ToolTimeDaySlider {
  time: number = 0;
  showTime: string = "";
  _disabled:boolean = false;

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }


  @Output() newDayTime: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this.recalculateAndNotify();
  }

  onChangeRange(rangeValue: any) {
    this.recalculateAndNotify();
  }

  onSlide(msc: MatSliderChange): void {
    if (msc.value) {
      this.showTime = this.formatLabel(msc.value);
    }
  }

  formatLabel(value: number | null): string {
    if (value == undefined) {
      return "undefined";
    }
    let h = Math.trunc(value);
    let m = (value - h) * 60;
    return ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);
  }

  nextStep(): void {
    this.time += 0.25;
    if (this.time > 24) this.time = 24;
    this.recalculateAndNotify();
  }

  prevStep(): void {
    this.time -= 0.25;
    if (this.time < 0) this.time = 0;
    this.recalculateAndNotify();
  }

  private recalculateAndNotify(): void {
    this.showTime = this.formatLabel(this.time);
    // notify in minutes from 00:00
    this.newDayTime.emit(this.time*60);
  }


}