import { Component, OnInit } from "@angular/core";

@Component({
  templateUrl: './nucalarms.component.html',
  styleUrls: ['./nucalarms.component.scss']
})

export class NucalarmsComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    //console.log("Connecting");
  }

}
