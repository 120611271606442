<div class="container-company box even">
  <div class="halfish-width flex">
    <div>
      <h2>Company details</h2>
    </div>
    <form [formGroup]="companyFormGroup" (submit)="save()">
      <div class="login-fields">
        <div>
          <mat-form-field class="form-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Domain name</mat-label>
            <input matInput formControlName="domainName">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Company type</mat-label>
            <input matInput formControlName="type" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Country</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of countries" [value]="country">{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Postal code</mat-label>
            <input matInput formControlName="postalCode" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Contact person</mat-label>
            <input matInput formControlName="contactPerson" required>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field">
            <mat-label>Contact Email</mat-label>
            <input matInput formControlName="contactEmail" required>
          </mat-form-field>
        </div>
      </div>

      <div align="end">
        <button mat-raised-button type="submit" color="primary" [disabled]="!companyFormGroup.valid">Save </button>
        <button mat-raised-button type="button" (click)="copyclipboard()">Copy </button> <!-- copy JSON object to clipboard -->
      </div>

    </form>
  </div>

  <div class="halfish-width flex">
    <div>
      <h2>Employees</h2>
    </div>
    <form [formGroup]="inviteFormGroup" (submit)="invite()">
      <div class="box align">
        <div class="protocol">
          <button mat-raised-button type="submit" color="primary" [disabled]="!inviteFormGroup.valid">Invite</button>
        </div>
        <mat-form-field class="fill">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required>
        </mat-form-field>
        <mat-form-field class="role">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option value="user">User</mat-option>
            <mat-option value="admin">Admin</mat-option>
          </mat-select>
          <!-- <input matInput formControlName="role" required> -->
        </mat-form-field>
      </div>
    </form>

    <mat-divider class="form-divider"></mat-divider>

    <employees-list></employees-list>

  </div>
</div>