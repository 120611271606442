<mat-accordion>
  <mat-expansion-panel [disabled]="from <= 0">
    <mat-expansion-panel-header>
      <mat-panel-title class="export-title">
        <mat-icon>file_download</mat-icon> Export
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p class="title">
      {{ from | date: 'dd MMM YYYY, h:mm a' }}
    </p>
    <p class="title">
      {{ to | date: 'dd MMM YYYY, h:mm a':'User' }}
    </p>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Duration</mat-label>
      <mat-select [(ngModel)]="duration" (selectionChange)="updateEndDate();">
        <mat-option *ngFor="let durationOption of durationOptions" [value]="durationOption.value">
          {{durationOption.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Period per file</mat-label>
      <mat-select [(ngModel)]="fileSize">
        <mat-option *ngFor="let fileSizeOption of fileSizeOptions" [value]="fileSizeOption.value">
          {{fileSizeOption.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Format</mat-label>
      <mat-select [(ngModel)]="format">
        <mat-option *ngFor="let exportFormat of exportFormats" [value]="exportFormat.format">
          {{exportFormat.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="title">
      <button mat-flat-button color="primary" [disabled]="ready" (click)="toggleStartStop();">
        <mat-icon>{{running?"stop":"play_arrow"}}</mat-icon> {{running?"Stop":"Start"}}
      </button>
    </div>

    <mat-progress-bar mode="determinate" value="{{100*loopIndex/totalLoops}}" *ngIf="running" style="margin-top: 10px;"></mat-progress-bar>
  </mat-expansion-panel>
</mat-accordion>



<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>