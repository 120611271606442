import { Component, OnInit } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DeviceMgt } from "src/app/common/_models";
import { ServiceDevices } from "src/app/common/_services";
import { v4 as uuidv4 } from 'uuid';

@Component({
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})

export class DevicesComponent implements OnInit {
  devices: DeviceMgt[] = [];
  selected: DeviceMgt | undefined;
  hide: boolean = false;

  // FormControl
  deviceFormGroup: FormGroup = new FormGroup({
    bn: new FormControl('', [Validators.required]),
    uuid: new FormControl('', [Validators.required]),
    alias: new FormControl(''),
    userName: new FormControl('', [Validators.required]),
    password: new FormControl(''),
    position: new FormGroup({
      lat: new FormControl('', [Validators.min(-90), Validators.max(90)]),
      lon: new FormControl('', [Validators.min(-180), Validators.max(180)])
    }),
    clientId: new FormControl('', [Validators.required]),
    enabled: new FormControl(false), //{value: false, disabled: true}),
    protocols: new FormGroup({
      http: new FormControl(false),
      mqtt: new FormControl(false),
      spectrum: new FormControl(false),
    })
  });



  constructor(
    private devicesMgt: ServiceDevices,
    private clipboard: Clipboard
  ) { }

  ngOnInit() {
    this.refresh();
  }

  private refresh(): void {
    this.new();
    this.devicesMgt.getDevicesMgt().subscribe(
      (data: DeviceMgt[]) => {
        this.devices = data;
        //console.warn("devs: \n" + JSON.stringify(this.devices));
      }
    );
  }

  onChange(event: any): void {
    //console.log("event: " + JSON.stringify(event.value));
    let selected: DeviceMgt = event.value;
    this.deviceFormGroup.reset();
    this.deviceFormGroup.patchValue(selected);
    this.isNew = false;
  }

  isNew: boolean = true;
  new(): void {
    this.selected = undefined;
    this.deviceFormGroup.reset();
    //console.log("NEW");

    let device: DeviceMgt = {
      bn: "",
      uuid: uuidv4(),
      alias: "",
      userName: "",
      password: "",
      clientId: "",
      enabled: true,
      protocols: {
        http: false,
        mqtt: false,
        spectrum: false
      }
    }

    this.deviceFormGroup.reset();
    this.deviceFormGroup.patchValue(device);
    this.isNew = true;
  }

  save(): void {
    //console.log("Is New: " + this.isNew);
    console.log("Device: " + JSON.stringify(this.deviceFormGroup.value));

    let device: DeviceMgt = this.deviceFormGroup.value;
    if (this.isNew) {
      this.devicesMgt.createNewDevice(device).subscribe({
        next: data => {
          //console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      });
    } else {
      this.devicesMgt.updateDevice(device).subscribe({
        next: data => {
          //console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      });
    }
  }

  copyclipboard(): void {
    //console.log("Copy to clipboard:");
    //console.log("Device: " + JSON.stringify(this.deviceFormGroup.value));

    this.clipboard.copy(JSON.stringify(this.deviceFormGroup.value))
  }

  delete(): void {
    //console.log("DELETE");
    let device: DeviceMgt = this.deviceFormGroup.value;
    this.devicesMgt.deleteDevice(device).subscribe({
      next: data => {
        //console.log("Done " + JSON.stringify(data));
      },
      error: error => {
        console.error(JSON.stringify(error));
      }
    }).add(() => {
      this.refresh();
    });
  }

}
