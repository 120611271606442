<div class="container" [ngStyle]="{width: containerWidth}">

  <img [src]="source" [ngStyle]="{width: containerWidth}">

  <div class="controls">
    <button mat-icon-button class="action-button" (click)="button_play();">
      <mat-icon>{{play?'pause':'play_arrow'}}</mat-icon>
    </button>

    <button mat-icon-button class="action-button" (click)="button_speed();">
      {{playSpeed}} s
    </button>


    <button mat-icon-button class="action-button" (click)="button_resize();">
      <mat-icon>fullscreen</mat-icon>
    </button>

  </div>
</div>