import { Component, OnInit } from "@angular/core";
import { Clipboard } from  "@angular/cdk/clipboard";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Company, User } from "src/app/common/_models";
import { ServiceCompanies, ServiceAuth } from "src/app/common/_services";
import { Country, countryListAlpha3 } from "src/app/common/_utils/countries";
import { validateHorizontalPosition } from "@angular/cdk/overlay";

@Component({
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class CompaniesComponent implements OnInit {
  user?: User;
  companies: Company[] = [];
  selected: Company | undefined;
  hide: boolean = false;

  countries = countryListAlpha3;

  // FormControl
  companyFormGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    name: new FormControl('', [Validators.required]),
    domainName: new FormControl(''),
    type: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required, Validators.maxLength(32)]),
    contactPerson: new FormControl('', [Validators.required]),
    contactEmail: new FormControl('', [Validators.required, Validators.email]),
    consult: new FormControl(''),
  });



  constructor(
    private companyService: ServiceCompanies,
    private authService: ServiceAuth,
    private clipboard: Clipboard
  ) {
    this.user = ServiceAuth.getLocalUser();
  }

  ngOnInit() {
    this.refresh();
  }

  private refresh(): void {
    this.new();
    this.companyService.getCompanies().subscribe(
      (data: Company[]) => {
        this.companies = data;
        //console.warn("devs: \n" + JSON.stringify(this.companies));
      }
    );
  }

  onChange(event: any): void {
    //console.log("event: " + JSON.stringify(event.value));
    let selected: Company = event.value;
    let country = this.countries.find(country => {
      return country.code === selected.country;
    });
    if (country) selected.country = country;
    this.companyFormGroup.setValue(selected);
    this.isNew = false;
  }

  isNew: boolean = true;
  new(): void {
    this.selected = undefined;
    this.companyFormGroup.reset();
    //console.log("NEW");
    this.isNew = true;
  }

  save(): void {
    //console.log("Is New: " + this.isNew);
    //console.log("Company: " + JSON.stringify(this.companyFormGroup.value));

    let company: Company = this.companyFormGroup.value;
    if (typeof company.country === "object") company.country = company.country.code;

    if (this.isNew) {
      this.companyService.createNewCompany(company).subscribe({
        next: data => {
          //console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      });
    } else {
      this.companyService.updateCompany(company).subscribe({
        next: data => {
          //console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      });
    }
  }

  copyclipboard(): void {
    console.log("Copy to clipboard: not implemented yet");
  }

  delete(): void {
    //console.log("DELETE");
    let company: Company = this.companyFormGroup.value;
    this.companyService.deleteCompany(company).subscribe({
      next: data => {
        //console.log("Done " + JSON.stringify(data));
      },
      error: error => {
        console.error(JSON.stringify(error));
      }
    }).add(() => {
      this.refresh();
    });
  }

}
