import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { User } from "src/app/common/_models";
import { ServiceUser } from "src/app/common/_services";


@Component({
  templateUrl: './user-conf.bs.html',
  styleUrls: ['./user-conf.bs.scss']
})

export class BottomSheetUserConf {

  userFormGroup: FormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    firstName: new FormControl({},Validators.required),
    lastName: new FormControl({},Validators.required),
    dateInvited: new FormControl(),
    dateRegistered: new FormControl(),
    dateLastLogin: new FormControl(),
    token: new FormControl(),
    phone: new FormControl({},Validators.required),
    company: new FormControl(),
    role: new FormControl()
  });

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetUserConf>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: User,
    private userService:ServiceUser
  ) {
    //console.log("User: " + JSON.stringify(data));
    this.userFormGroup.patchValue(data);
  }

  updateUser() {
    //console.log(this.userFormGroup.value);
    let tmpUser:User = this.userFormGroup.value;

    this.userService.updateUser(tmpUser).subscribe({
      next: data => {

      },
      error: error => {

      }
    });

    // Save and Close it
    //this.bottomSheetRef.dismiss();
  }

}
