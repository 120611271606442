import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MapData } from "../_models";

@Injectable()

export class ServiceMaps {

  constructor(
    private http: HttpClient,
  ) { }

  getPositions(): Observable<MapData> {
    return this.http.get<MapData>('/api/data/position');
  }

  setPosition(name: string, lat: number, lon: number): Observable<MapData> {
    return this.http.put<MapData>('/api/data/position', { name: name, lat: lat, lon: lon });
  }

}
