import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardAuth } from './common/_guards';
import { LoginComponent } from './login';
import { 
  DashboardComponent,
  OsmmapComponent,
  ArrowheadComponent,
  NucalarmsComponent,
  StorageComponent,
  CamerasComponent,
  NucleusComponent,
  ReportsComponent,
  DevicesComponent,
  ShadowsComponent,
  DocumentationComponent,
  LogsComponent,
  CompaniesComponent,
  CompanyComponent,
  UsersComponent
} from './nucleus';

const routes: Routes = [
  {
    path:'',
    component: NucleusComponent,
    canActivate: [GuardAuth],
    children: [
      {
        path:'',
        component: DashboardComponent
      },
      {
        path:'maps',
        component: OsmmapComponent
      },
      {
        path:'devices',
        component: DevicesComponent
      },
      {
        path:'shadows',
        component: ShadowsComponent
      },
      {
        path:'arrowhead',
        component: ArrowheadComponent
      },
      {
        path:'alarms',
        component: NucalarmsComponent
      },
      {
        path:'storage',
        component: StorageComponent
      },
      {
        path:'cameras',
        component: CamerasComponent
      },
      {
        path:'reports',
        component: ReportsComponent
      },
      {
        path:'documentation',
        component: DocumentationComponent
      },
      {
        path:'logs',
        component: LogsComponent
      },
      {
        path:'companies',
        component: CompaniesComponent
      },
      {
        path:'company',
        component: CompanyComponent
      },
      {
        path:'users',
        component: UsersComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
