User Configuration

<form [formGroup]="userFormGroup" (submit)="updateUser()" >
  <div>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput formControlName="password">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Firstname</mat-label>
      <input matInput formControlName="firstName">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Lastname</mat-label>
      <input matInput formControlName="lastName">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input matInput formControlName="phone">
    </mat-form-field>
  </div>

  <div align="end">
    <button mat-raised-button color="primary" type="submit" [disabled]="!userFormGroup.valid">
      Save
    </button>
  </div>

</form>
