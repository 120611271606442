import { Component, OnInit } from "@angular/core";
import { Clipboard } from  "@angular/cdk/clipboard";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Company, User, Invite } from "src/app/common/_models";
import { ServiceCompanies, ServiceAuth, ServiceUser } from "src/app/common/_services";
import { Country, countryListAlpha3 } from "src/app/common/_utils/countries";
import { validateHorizontalPosition } from "@angular/cdk/overlay";

@Component({
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {
  user?: User;
  hide: boolean = false;

  countries = countryListAlpha3;

  // FormControl
  companyFormGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    name: new FormControl('', [Validators.required]),
    domainName: new FormControl(''),
    type: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required, Validators.maxLength(32)]),
    contactPerson: new FormControl('', [Validators.required]),
    contactEmail: new FormControl('', [Validators.required, Validators.email]),
  });

  inviteFormGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    role: new FormControl('user', [Validators.required])
  });


  constructor(
    private companyService: ServiceCompanies,
    private userService: ServiceUser,
    private authService: ServiceAuth,
    private clipboard: Clipboard
  ) {
    this.user = ServiceAuth.getLocalUser();
    this.companyService.getCompany().subscribe({
      next: (data: Company) => {
        let country = this.countries.find(country => {
          return country.code === data.country;
        });
        if (country) data.country = country;
        this.companyFormGroup.setValue(data);
      },
      error: error => {
        console.error(error);
      }
    })
  }

  ngOnInit() {
  }

  save(): void {
    console.log("Company: " + JSON.stringify(this.companyFormGroup.value));

    let company: Company = this.companyFormGroup.value;
    if (typeof company.country === "object") company.country = company.country.code;

    
      this.companyService.updateCompany(company).subscribe({
        next: data => {
          console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      });
  }

  copyclipboard(): void {
    console.log("Copy to clipboard:");
  }

  delete(): void {
    console.log("DELETE");
    let company: Company = this.companyFormGroup.value;
    this.companyService.deleteCompany(company).subscribe({
      next: data => {
        console.log("Done " + JSON.stringify(data));
      },
      error: error => {
        console.error(JSON.stringify(error));
      }
    });
  }

  invite(): void {
    let invite: Invite = this.inviteFormGroup.value;    
    this.userService.inviteUser(invite).subscribe({
      error: error => {
        console.error(JSON.stringify(error))
      }
    });
  }

}
