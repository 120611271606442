<div class="row">
  <div class="column">
    <div style="width: 200px;">
      <button mat-stroked-button (click)="prevStep()" [disabled]="_disabled">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button mat-stroked-button (click)="nextStep()" [disabled]="_disabled">
        <mat-icon>navigate_next</mat-icon>
      </button>
      <span style="margin-left: 10px;">{{showTime}}</span>
    </div>
  </div>
  <div class="column" style="width: 100%;">
    <mat-slider color="primary" tickInterval="1" step="0.25" min="0" max="24"
      style="width: 100%;" (change)="onChangeRange($event)" (input)="onSlide($event)" [(ngModel)]="time"  [disabled]="_disabled"></mat-slider>
  </div>
</div>