import { Component, OnInit } from "@angular/core";
import { CameraInfo, CamerasInfo } from "src/app/common/_models";
import { ServiceCameras } from "src/app/common/_services";

@Component({
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.scss']
})

export class CamerasComponent implements OnInit {

  camerasInfo?: CamerasInfo;

  constructor(
    private cameraService: ServiceCameras
  ) { }

  ngOnInit() {
    console.log("Smart cameras");
    this.cameraService.getCamerasInfo().subscribe(
      (data: CamerasInfo) => {
        console.warn(JSON.stringify(data));

        if (data.cameras != null && data.cameras.length != 0) {
          let cam: CameraInfo = data.cameras[0];

          for (let i = 0; i < 10; i++) {
            data.cameras.push(cam);
          }

          this.camerasInfo = data;
        }

      }
    );
  }

}

/* sdfsd

    console.log("Smart cameras");
    this.cameraService.getCamerasInfo().subscribe(
      (data: CamerasInfo) => {
        console.warn(JSON.stringify(data));

        if (data.cameras != null && data.cameras.length != 0) {
          let cam: CameraInfo = data.cameras[0];

          for (let i = 0; i < 10; i++) {
            data.cameras.push(cam);
          }

          this.camerasInfo = data;
        }

      }
    );
*/