import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataFileInfo, DataFileList } from "src/app/common/_models";
import { ServiceData, ServiceInfo } from "src/app/common/_services";
import { BottomSheetData } from "../../bottomsheets";
import { HttpErrorResponse } from "@angular/common/http";
import FileSaver from "file-saver";

@Component({
  selector: 'files-list',
  templateUrl: './files.list.html',
  styleUrls: ['./files.list.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ListFiles implements OnInit, OnDestroy {
  fileList?: DataFileList;

  loading: boolean = false;
  filterValue: string = "";
  path: string = "";

  displayedColumns: string[] = ['select', 'name', 'download', 'size', 'lastModified'];
  dataFiles: MatTableDataSource<DataFileInfo> = new MatTableDataSource();
  selection = new SelectionModel<DataFileInfo>(true, []);

  expandedElements: DataFileInfo[] = [];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataFiles.sort = sort;
  }

  constructor(
    private bottomSheet: MatBottomSheet,
    private dataService: ServiceData
  ) {
  }

  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy() { }


  refresh(): void {
    this.loading = true;
    this.dataFiles = new MatTableDataSource();
    this.selection = new SelectionModel<DataFileInfo>(true, []);

    this.dataService.getFiles(this.path).subscribe({
      next: data => {
        if (this.path.length > 0) {
          data.files.unshift({ name: '..', dir: true }); //, size: undefined, lastModified:undefined}); // Add ascend item
        }
        //console.log(data.files);
        this.dataFiles = new MatTableDataSource(data.files);
        this.applyFilter(this.filterValue);
      },
      error: error => {
        console.error("Error: " + JSON.stringify(error), 5000);
      }
    }).add(() => { this.loading = false; });

  }

  applyFilter(event: any) {
    this.filterValue = (typeof event === 'object') ? event.target.value : "";
    this.dataFiles.filter = this.filterValue;
  }

  // ---- TABLE specific
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataFiles.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataFiles.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: DataFileInfo): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row 3`;
  }

  toggleColumn(name: string): void {
    //this.logger.debug(this.className, "addColumn " + name);
    if (this.displayedColumns.includes(name)) {
      const index = this.displayedColumns.indexOf(name, 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    } else {
      this.displayedColumns.push(name);
    }
    // this.logger.debug(this.className, "addColumn " + name + " done!");
  }

  // ---- FUNCTIONS
  fileClicked(file: DataFileInfo) {
    if (file.name === '..') {
      this.path = this.path.slice(0, this.path.lastIndexOf('/'));
      this.refresh();
    }
    else if (file.dir) {
      this.path += '/' + file.name;
      this.refresh();
    }
  }

  fileEdit(file: DataFileInfo) {
    //console.log("deviceEdit: "+device.bn);
    /*this.logger.debug(this.className, "deviceEdit: " + device.mac);

    let bottomSheetRef = this.bottomSheet.open(BottomSheetDevice, { data: device });
    bottomSheetRef.afterDismissed().subscribe(
      data => {
        this.logger.debug(this.className, "BottomSheetDevice: " + JSON.stringify(data));
      }
    );*/
  }

  fileDownload(file: DataFileInfo) {

  }

  getData(files: DataFileInfo[]) {
    const bottomSheetRef = this.bottomSheet.open(BottomSheetData, { data: files });
  }

  removeItemOnce(arr: any[], value: any): any[] {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  download(path: string, fileName: string): void {
    this.dataService.downloadFile(path, fileName).subscribe(
      (data: any) => { // Blob input
        FileSaver.saveAs(data, fileName);
      },
      (error: HttpErrorResponse) => {
        console.warn("error: " + error.status + " " + error.statusText + " | ");
      }
    );
  }

}
