import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { User } from "../_models";
import { ServiceAuth } from "../_services";


@Injectable()
export class GuardAuth implements CanActivate {
  private className: string = "GuardAuth";

  constructor(
    private router: Router,
    private auth: ServiceAuth
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log("canActivate");
    let user: User = ServiceAuth.getLocalUser();
    //console.log("Coockie User: " + JSON.stringify(user));
    if (user?.registered) return true;
    //console.log("No Cookie!", 1000);
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}