Complete Registration

<form [formGroup]="userFormGroup" (submit)="registerUser()" >
  <div>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" readonly>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Firstname</mat-label>
      <input matInput formControlName="firstName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Lastname</mat-label>
      <input matInput formControlName="lastName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input matInput formControlName="phone">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Company</mat-label>
      <input matInput formControlName="company">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Role</mat-label>
      <input matInput formControlName="role">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Date invited</mat-label>
      <input matInput formControlName="dateInvited">
    </mat-form-field>
  </div>

  <div align="end">
    <button mat-raised-button color="primary" type="submit" [disabled]="!userFormGroup.valid">
      Save
    </button>
  </div>

</form>
