import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor() { }

  makePopup(data: any): string {
    let ret = `` +
      `<div>Device: ${ data.name }</div>`;
      if (data.owner !== undefined) 
        ret += `<div>Owner: ${ data.owner }</div>`;
      ret += `<div>Last update: ${ data.lastUpdate }</div>`;
      /*if (data.uptime !== undefined) 
        ret += `<div>Uptime: ${ data.uptime }</div>`;*/

      return ret;
  }
}
