// Core Modules
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthLogin, Info } from "../common/_models";
import { ServiceAuth, ServiceInfo } from "../common/_services";
import { BottomSheetStageUser } from "../nucleus/bottomsheets/stage-user/stage-user.bs";

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  private returnUrl: string = "/";
  hide: boolean = true;
  info: Info | null = null;
  message: string = "loading....";


  userFormGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });


  constructor(
    private bottomSheet: MatBottomSheet,
    private auth: ServiceAuth,
    private infoService: ServiceInfo,
    private route: ActivatedRoute,
    private router: Router
  ) {
    console.error("LOG OUT 1");
    this.auth.logout();
  }

  ngOnInit(): void {
    // Get return URL
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.infoService.getBasic().subscribe({
      next: (data: Info) => {
        this.info = data;
        this.message = "";
      },
      error: error => {
        console.error(JSON.stringify(error));
        this.message = "Error: " + JSON.stringify(error);
      }
    });
  }

  login(): void {
    let authlogin: AuthLogin = this.userFormGroup.getRawValue();
    this.auth.login(authlogin).subscribe({
      next: data => {
        if (data != null) {
          //console.log(data);
          //console.log("Login OK navigate to: " + this.returnUrl);
          if (data.registered) {
            //console.log(this.returnUrl)
            this.router.navigate([this.returnUrl]);
          } else {
            let bottomSheetRef = this.bottomSheet.open(BottomSheetStageUser, { data: data });
            bottomSheetRef.afterDismissed().subscribe(
              data => {
                console.error("LOG OUT 2");
                this.auth.logout();
              }
            );
          }
        }
      },
      error: error => {
        console.error(error);
        this.message = "Wrong email or password";
      }
    });
  }
}
