import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Camera, CamerasInfo } from "../_models";

@Injectable()

export class ServiceCameras {
    constructor(
        private http: HttpClient
    ) {
    }

    getCameras(): Observable<Camera> {
        return this.http.get<Camera>('/api/cameras');
    }


    getCamera(uuid: string, ch: number): Observable<any> {
        return this.http.get<any>('/api/camera/' + uuid + '/' + ch, { responseType: "blob" as "json" });
    }

    getCamerasInfo(): Observable<CamerasInfo> {
        return this.http.get<CamerasInfo>('/api/camerasinfo');
    }
}
