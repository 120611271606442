import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceMgt } from "../_models";
//import { DevicesList } from "../_models";

@Injectable()

export class ServiceDevices {

  constructor(
    private http: HttpClient,
  ) { }


  getDevicesMgt(): Observable<DeviceMgt[]> {
    return this.http.get<DeviceMgt[]>('/api/uuid');
  }

  createNewDevice(device:DeviceMgt):Observable<any> {
    return this.http.post<any[]>('/api/uuid', device);
  }

  updateDevice(device:DeviceMgt):Observable<any> {
    return this.http.put<any[]>('/api/uuid', device);
  }

  deleteDevice(device:DeviceMgt):Observable<any> {
    return this.http.delete<any[]>('/api/uuid/'+device.uuid);
  }
}
