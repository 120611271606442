<div class="container-user">
  <mat-form-field class="half-width">
    <mat-label>User</mat-label>
    <mat-select (selectionChange)="onChange($event)" [(ngModel)]="selected">
      <mat-option *ngFor="let user of users" [value]="user">{{user.firstName}} {{user.lastName}}</mat-option>
    </mat-select>
  </mat-form-field>

  <form [formGroup]="userFormGroup" (submit)="save()">
    <ng-template matStepLabel>Enter your password</ng-template>
    <div class="login-fields">
      <div>
        <mat-form-field class="half-width">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}} </mat-icon>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option value="user">User</mat-option>
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="root">Root</mat-option>
          </mat-select>
          <!-- <input matInput formControlName="role" required> -->
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Company</mat-label>
          <mat-select formControlName="companyId">
            <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
          </mat-select>
          <!-- <input matInput formControlName="role" required> -->
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Date invited</mat-label>
          <input matInput formControlName="dateInvited" readonly>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Date registered</mat-label>
          <input matInput formControlName="dateRegistered" readonly>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Last login</mat-label>
          <input matInput formControlName="dateLastLogin" readonly>
        </mat-form-field>
      </div>
    </div>

    <div align="end">
      <button mat-raised-button type="button" color="warn" (click)="delete()" [disabled]="isNew">Delete </button>
      <button mat-raised-button type="button" (click)="new()">New </button>
      <button mat-raised-button type="submit" color="primary" [disabled]="!userFormGroup.valid">Save </button>
      <button mat-raised-button type="button" (click)="copyclipboard()">Copy </button> <!-- copy JSON object to clipboard -->
    </div>

  </form>
</div>