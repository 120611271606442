import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { DataInfo, DataSource, DeviceInfo, ServiceInfo } from "src/app/common/_models";
import { ServiceData } from "src/app/common/_services";

// HighCharts
import { StockChart } from 'angular-highcharts';
import { Options } from 'highcharts';
import Highcharts from "highcharts/highstock";
import exporting from 'highcharts/modules/exporting';
import { HttpErrorResponse } from "@angular/common/http";
import FileSaver from "file-saver";
import { FftExportConfig } from "src/app/common/_tools";
exporting(Highcharts); // Magic to export


@Component({
  templateUrl: './data.bs.html',
  styleUrls: ['./data.bs.scss']
})

export class BottomSheetData implements OnInit, AfterViewInit {
  devices: DeviceInfo[];
  loading: boolean = false;
  loadedGraph: boolean = false;
  loadingRange: boolean = false;
  loadingData: boolean = false;
  services = new FormControl();
  totalPoints: number = 0;
  dataInfo?: DataInfo;
  minDate?: Date;
  maxDate?: Date;
  dataFrom?: Date;
  dataTo?: Date;
  showFFT: boolean = false;
  daytime: number = 0;
  dateBD?: Date;
  dateTimeBigData?: Date;
  fftData: DataSource[] = [];
  continuousPlotting: boolean = false;


  // HighCharts
  timezoneoffset: number = new Date().getTimezoneOffset();
  options: Options =
    {
      chart: {
        zoomType: 'x'
      },
      credits: {
        enabled: true,
        href: "https://www.thingwave.com",
        text: "https://www.thingwave.com"
      },
      legend: {
        enabled: true
      },
      title: {
        text: ""
      },
      series: undefined,
      xAxis: {
        ordinal: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.2f}</b><br/>',
      },
      yAxis: {
        min: null,
        max: null
      },
      plotOptions: {
        series: {
          marker: {
            radius: 4
          }
        }
      },
      time: {
        timezoneOffset: this.timezoneoffset
      }
    };
  stockChart: StockChart = new StockChart(this.options);


  @ViewChild('graphArea', { read: ElementRef, static: false }) private graphArea?: ElementRef;
  graphWidth: number = 0;
  graphHeight: number = 0;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetData>,
    public changeDetector: ChangeDetectorRef,
    private dataService: ServiceData,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DeviceInfo[]
  ) {
    //bottomSheetRef.disableClose = true;
    this.devices = data;
  }

  ngAfterViewInit(): void {
    this.graphHeight = this.graphArea?.nativeElement.offsetHeight;
    this.graphWidth = this.graphArea?.nativeElement.offsetWidth;
  }

  ngOnInit() {
    this.loading = true;
    this.dataService.getServicesOf(this.devices).subscribe({
      next: data => {
        this.dataInfo = data;
      },
      error: error => {
        console.error(error);
        /*this.dataInfo = {
          services: [
            {
              id: '123',
              alias: 'fakeService 1',
              points: 111000,
              from: 23234,
              to: 34234234
            },
            {
              id: '13314sad',
              alias: 'fakeService 2',
              points: 45345,
              from: 3464356,
              to: 342342334
            }
          ]
        };
        */
      }
    }).add(() => { this.loading = false; });

  }

  requestGraph() {
    this.showFFT = false;
    if (this.isBigDataMode()) {
      if (!this.dateTimeBigData) return;


      // Fix to UTC
      let from: number = this.dateTimeBigData.getTime() + new Date().getTimezoneOffset() * 60000;
      let to: number = from + 15 * 60000;
      this.loadingRange = true;
      this.loadedGraph = false;
      this.loadingData = true;

      let selectedServices: ServiceInfo[] = this.services.value;
      let options: Options = this.copyOptions();
      let dataSources: DataSource[] = [];



      this.dataService.getDataOf(this.devices.map(d => d.bn), selectedServices.map(d => d.id), from, to).subscribe(
        {
          next: ((data: DataSource[]) => {
            dataSources = data;
            options.series = [];

            let points: number = 0;

            dataSources.forEach(dat => {
              points += dat.data.length;
              options.series?.push(
                {
                  type: 'line',
                  data: dat.data,
                  name: dat.bn + dat.service.alias,
                  lineWidth: 1,
                  marker: {
                    enabled: true,
                    radius: 2,
                    states: {
                      hover: {
                        lineWidthPlus: 4
                      }
                    }
                  }
                }
              );
            });
            this.totalPoints = points;
            this.stockChart = new StockChart(options);
          }),
          error: ((error: HttpErrorResponse) => { console.error("Error: " + error.status + " " + error.statusText); }),
          complete: (() => {
            this.loadingRange = false;
            this.loadedGraph = true;
            this.loadingData = false;
            this.changeDetector.markForCheck();
          })
        }
      );

    } else {
      if (!this.dataFrom) return;
      if (!this.dataTo) return;
      this.dataFrom.setHours(0, 0, 0, 0);
      //this.dataTo.setHours(23, 59, 59, 999);
      this.dataTo.setHours(0, 1, 0, 0);
      let from: number = this.dataFrom.getTime();
      let to: number = this.dataTo.getTime();
      if (to < from) return;
      this.loadingRange = true;
      this.loadingData = true;

      let selectedServices: ServiceInfo[] = this.services.value;
      let options: Options = this.copyOptions();
      let dataSources: DataSource[] = [];

      this.dataService.getDataOf(this.devices.map(d => d.bn), selectedServices.map(d => d.id), from, to).subscribe(
        {
          next: ((data: DataSource[]) => {
            dataSources = data;
            options.series = [];

            let points: number = 0;

            dataSources.forEach(dat => {
              points += dat.data.length;
              options.series?.push(
                {
                  type: 'line',
                  data: dat.data,
                  name: dat.bn + dat.service.alias,
                  lineWidth: 1,
                  marker: {
                    enabled: true,
                    radius: 2,
                    states: {
                      hover: {
                        lineWidthPlus: 4
                      }
                    }
                  }
                }
              );
            });
            this.totalPoints = points;
            this.stockChart = new StockChart(options);
          }),
          error: ((error: HttpErrorResponse) => { console.error("Error: " + error.status + " " + error.statusText); }),
          complete: (() => {
            this.loadingRange = false;
            this.loadedGraph = true;
            this.loadingData = false;
            this.changeDetector.markForCheck();
          })
        }
      );
    }

  }

  requestFFT() {

    console.warn('request FFT');
    this.showFFT = true;
    this.loadedGraph = false;
    this.loadingData = true;

    if (this.isBigDataMode()) {
      if (!this.dateTimeBigData) return;

      // Fix to UTC
      let from: number = this.dateTimeBigData.getTime() + new Date().getTimezoneOffset() * 60000;
      let to: number = from + 30 * 60000;
      this.loadingRange = true;

      let selectedServices: ServiceInfo[] = this.services.value;

      this.dataService.getDataOf(this.devices.map(d => d.bn), selectedServices.map(d => d.id), from, to).subscribe(
        {
          next: ((data: DataSource[]) => {
            this.fftData = data;
            console.log("data in!");

          }),
          error: ((error: HttpErrorResponse) => { console.error("Error: " + error.status + " " + error.statusText); }),
          complete: (() => {
            this.loadingRange = false;
            this.loadedGraph = true;
            this.loadingData = false;
            this.changeDetector.markForCheck();
          })
        }
      );

    } else {
      console.error("Not implemented, not enought datapoints");
    }
  }

  updatePoints(): void {
    let points: number = 0;
    let services: ServiceInfo[] = this.services.value;

    services.forEach(service => {
      if (this.minDate == null) {
        this.minDate = new Date(service.from);
      }
      if (this.maxDate == null) {
        this.maxDate = new Date(service.to);
      }

      if (this.minDate.getTime() > service.from) {
        this.minDate = new Date(service.from);
      }

      if (this.maxDate.getTime() < service.to) {
        this.maxDate = new Date(service.to);
      }
      points += service.points;
    });

    console.error(this.minDate + "\n\n" + this.maxDate);

    this.dataFrom = this.minDate;
    this.dataTo = this.maxDate;
    this.totalPoints = points;
  }


  fftExportConfig?: FftExportConfig;
  public fftDownloadCSV(): void {
    this.fftExportConfig = {
      id: this.devices[0].bn,
      time: this.dateTimeBigData!,
      format: "CSV"
    };
  }

  //-----

  public close() {
    this.bottomSheetRef.dismiss();
  }


  // Internal
  newDayTime(value: number): void {
    this.daytime = value;

    this.dateTimeBigData = new Date(this.dateBD ? this.dateBD.getTime() : 0);
    this.dateTimeBigData.setMinutes(this.daytime);

    if (this.continuousPlotting) {
      if (this.showFFT) {
        this.requestFFT();
      } else {
        this.requestGraph();
      }
    }
  }

  updatedCalendarBD(): void {
    this.dateTimeBigData = new Date(this.dateBD ? this.dateBD.getTime() : 0);
    this.dateTimeBigData.setMinutes(this.daytime);
  }

  private copyOptions(): Options {
    let options: Options = JSON.parse(JSON.stringify(this.options));
    return options;
  }

  applyYScaleMin(event: any): void {
    let min: number = event.target.value;
    console.log(JSON.stringify(this.options.yAxis));
    this.stockChart.ref.update({
      yAxis: {
        min: min
      }
    }, true);
  }

  applyYScaleMax(event: any): void {
    let max: number = event.target.value;
    this.stockChart.ref.update({
      yAxis: {
        max: max
      }
    }, true);
  }

  checkIfFFT(): boolean {
    let selectedServices: ServiceInfo[] = this.services.value;
    if (!selectedServices) return false;

    for (let i = 0; i < selectedServices.length; i++) {
      if (!selectedServices[i].alias.startsWith('Acceleration-')) return false;
    }
    return true;
  }

  isBigDataMode(): boolean {
    let selectedServices: ServiceInfo[] = this.services.value;
    if (!selectedServices) return false;
    let counter: number = 0;
    for (let i = 0; i < selectedServices.length; i++) {
      counter += selectedServices[i].points;
    }
    return counter > 10000000;
  }



}