<div *ngIf="!showProtectBanner; else protectTemplate;" class="core-container"
  [class.core-is-mobile]="mobileQuery.matches">

  <mat-toolbar color="primary" class="core-toolbar">
    <button mat-icon-button (click)="snavMenu.toggle()">
      <mat-icon *ngIf="!snavMenu.opened">menu</mat-icon>
      <mat-icon *ngIf="snavMenu.opened">menu_open</mat-icon>
    </button>

    Nucleus

    <span class="core-spacer"></span>

    <button mat-button [matMenuTriggerFor]="user_account_menu">
      <mat-icon>person</mat-icon> {{ user?.email }} <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #user_account_menu="matMenu">
      <button mat-menu-item (click)="userMenu()">
				<mat-icon>account_box</mat-icon>Account
			</button>
      <mat-divider></mat-divider>
      <button mat-menu-item routerLink="/login">
        <mat-icon>exit_to_app</mat-icon>Logout
      </button>
    </mat-menu>


  </mat-toolbar>

  <mat-sidenav-container class="core-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snavMenu [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56" position="start">

      <mat-nav-list>
        <div>
          <a mat-list-item routerLink="/" *ngIf="user?.menus?.includes('Dashboard')">
            <mat-icon>dashboard</mat-icon>Dashboard
          </a>
          <a mat-list-item routerLink="maps" *ngIf="user?.menus?.includes('Maps')">
            <mat-icon>map</mat-icon>Maps
          </a>
          <a mat-list-item routerLink="devices" *ngIf="user?.menus?.includes('Devices')">
            <mat-icon>list</mat-icon>Devices
          </a>
          <a mat-list-item routerLink="shadows" *ngIf="user?.menus?.includes('Shadows')">
            <mat-icon>list</mat-icon>Shadows
          </a>
          <a mat-list-item routerLink="alarms" *ngIf="user?.menus?.includes('Alarms')">
            <mat-icon>alarm</mat-icon>Alarms
          </a>
          <a mat-list-item routerLink="storage" *ngIf="user?.menus?.includes('Storage')">
            <mat-icon>storage</mat-icon>Storage
          </a>
          <a mat-list-item routerLink="cameras" *ngIf="user?.menus?.includes('Cameras')">
            <mat-icon>cameras</mat-icon>Cameras
          </a>
          <a mat-list-item routerLink="reports" *ngIf="user?.menus?.includes('Reports')">
            <mat-icon>article</mat-icon>Reports
          </a>
          <a mat-list-item routerLink="documentation" >
            <mat-icon>article</mat-icon>Documentation
          </a>
          <a mat-list-item routerLink="arrowhead" *ngIf="user?.menus?.includes('Arrowhead')">
            <mat-icon>north_east</mat-icon>Arrowhead
          </a>
          <a mat-list-item routerLink="companies" *ngIf="user?.role === 'root'">
            <mat-icon>list</mat-icon>Companies
          </a>
          <a mat-list-item routerLink="users" *ngIf="user?.role === 'root'">
            <mat-icon>list</mat-icon>Users
          </a>
          <a mat-list-item routerLink="company" *ngIf="user?.role === 'admin'">
            <mat-icon>list</mat-icon>Company
          </a>
          <a mat-list-item routerLink="logs"> <!-- *ngIf="user?.menus?.includes('Logs')"> -->
            <mat-icon>article</mat-icon>Logs
          </a>
        </div>
        <div class="sidenav-bottom">
          <a mat-list-item routerLink="/options"  disabled>
            <mat-icon>settings</mat-icon>Options
          </a>
          <!--a mat-list-item (click)="snavMenu.toggle(); openDialogSupport();">
						<mat-icon>help</mat-icon>Support
					</a>
					<a mat-list-item (click)="snavMenu.toggle(); openDialogInfo();">
						<mat-icon>info</mat-icon>Info
					</a-->
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>


  <!-- FOOTER -->
  <div class="footer">
    <small>
      <a href="https://www.thingwave.eu" target="_blank" style="color: #ea5c1e">ThingWave</a>
      IoT Nucleus&trade;
    </small>
  </div>
</div>

<ng-template #protectTemplate>
  <div class="logo"></div>
  <mat-progress-bar mode="determinate" [value]="protectCounter"></mat-progress-bar>
</ng-template>
