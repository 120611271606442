import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DevicesList } from "../_models";

@Injectable()

export class ServiceDevice {

  constructor(
    private http: HttpClient,
  ) { }

  getDevices(): Observable<DevicesList> {
    return this.http.get<DevicesList>('/api/devices');
  }


}