export interface FileExport {
  name: string;
  type: string;
  data: string;
}

export class File {
  data: FileData;
  info: FileInfo;

  constructor() {
    this.data = new FileData();
    this.info = new FileInfo();
  }
}

export class FileData {
  _id: string;
  dataB64: string;
  constructor() {
    this._id = "";
    this.dataB64 = "";
  }
}

export class FileInfo {
  _id: string;
  _dataId: string;
  name: string;
  size: number;
  description: string;
  date: number;
  owner: string;
  type: string;
  base: string;

  constructor() {
    this._id = "";
    this._dataId = "";
    this.name = "";
    this.size = 0;
    this.description = "";
    this.date = 0;
    this.owner = "";
    this.type = "";
    this.base = "";
  }
}