export interface Country {
  code: string;
  name: string;
}

// Country names object using 3-letter country codes to reference country name
// ISO 3166 Alpha-3 Format: [3 letter Country Code]: [Country Name]
// Sorted alphabetical by country name (special characters on bottom)
export const countryListAlpha3: Country[] = [
    { code: "AFG", name: "Afghanistan"},
    { code: "ALB", name: "Albania"},
    { code: "DZA", name: "Algeria"},
    { code: "ASM", name: "American Samoa"},
    { code: "AND", name: "Andorra"},
    { code: "AGO", name: "Angola"},
    { code: "AIA", name: "Anguilla"},
    { code: "ATA", name: "Antarctica"},
    { code: "ATG", name: "Antigua and Barbuda"},
    { code: "ARG", name: "Argentina"},
    { code: "ARM", name: "Armenia"},
    { code: "ABW", name: "Aruba"},
    { code: "AUS", name: "Australia"},
    { code: "AUT", name: "Austria"},
    { code: "AZE", name: "Azerbaijan"},
    { code: "BHS", name: "Bahamas (the)"},
    { code: "BHR", name: "Bahrain"},
    { code: "BGD", name: "Bangladesh"},
    { code: "BRB", name: "Barbados"},
    { code: "BLR", name: "Belarus"},
    { code: "BEL", name: "Belgium"},
    { code: "BLZ", name: "Belize"},
    { code: "BEN", name: "Benin"},
    { code: "BMU", name: "Bermuda"},
    { code: "BTN", name: "Bhutan"},
    { code: "BOL", name: "Bolivia (Plurinational State of)"},
    { code: "BES", name: "Bonaire, Sint Eustatius and Saba"},
    { code: "BIH", name: "Bosnia and Herzegovina"},
    { code: "BWA", name: "Botswana"},
    { code: "BVT", name: "Bouvet Island"},
    { code: "BRA", name: "Brazil"},
    { code: "IOT", name: "British Indian Ocean Territory (the)"},
    { code: "BRN", name: "Brunei Darussalam"},
    { code: "BGR", name: "Bulgaria"},
    { code: "BFA", name: "Burkina Faso"},
    { code: "BDI", name: "Burundi"},
    { code: "CPV", name: "Cabo Verde"},
    { code: "KHM", name: "Cambodia"},
    { code: "CMR", name: "Cameroon"},
    { code: "CAN", name: "Canada"},
    { code: "CYM", name: "Cayman Islands (the)"},
    { code: "CAF", name: "Central African Republic (the)"},
    { code: "TCD", name: "Chad"},
    { code: "CHL", name: "Chile"},
    { code: "CHN", name: "China"},
    { code: "CXR", name: "Christmas Island"},
    { code: "CCK", name: "Cocos (Keeling) Islands (the)"},
    { code: "COL", name: "Colombia"},
    { code: "COM", name: "Comoros (the)"},
    { code: "COD", name: "Congo (the Democratic Republic of the)"},
    { code: "COG", name: "Congo (the)"},
    { code: "COK", name: "Cook Islands (the)"},
    { code: "CRI", name: "Costa Rica"},
    { code: "HRV", name: "Croatia"},
    { code: "CUB", name: "Cuba"},
    { code: "CUW", name: "Curaçao"},
    { code: "CYP", name: "Cyprus"},
    { code: "CZE", name: "Czechia"},
    { code: "CIV", name: "Côte d'Ivoire"},
    { code: "DNK", name: "Denmark"},
    { code: "DJI", name: "Djibouti"},
    { code: "DMA", name: "Dominica"},
    { code: "DOM", name: "Dominican Republic (the)"},
    { code: "ECU", name: "Ecuador"},
    { code: "EGY", name: "Egypt"},
    { code: "SLV", name: "El Salvador"},
    { code: "GNQ", name: "Equatorial Guinea"},
    { code: "ERI", name: "Eritrea"},
    { code: "EST", name: "Estonia"},
    { code: "SWZ", name: "Eswatini"},
    { code: "ETH", name: "Ethiopia"},
    { code: "FLK", name: "Falkland Islands (the) [Malvinas]"},
    { code: "FRO", name: "Faroe Islands (the)"},
    { code: "FJI", name: "Fiji"},
    { code: "FIN", name: "Finland"},
    { code: "FRA", name: "France"},
    { code: "GUF", name: "French Guiana"},
    { code: "PYF", name: "French Polynesia"},
    { code: "ATF", name: "French Southern Territories (the)"},
    { code: "GAB", name: "Gabon"},
    { code: "GMB", name: "Gambia (the)"},
    { code: "GEO", name: "Georgia"},
    { code: "DEU", name: "Germany"},
    { code: "GHA", name: "Ghana"},
    { code: "GIB", name: "Gibraltar"},
    { code: "GRC", name: "Greece"},
    { code: "GRL", name: "Greenland"},
    { code: "GRD", name: "Grenada"},
    { code: "GLP", name: "Guadeloupe"},
    { code: "GUM", name: "Guam"},
    { code: "GTM", name: "Guatemala"},
    { code: "GGY", name: "Guernsey"},
    { code: "GIN", name: "Guinea"},
    { code: "GNB", name: "Guinea-Bissau"},
    { code: "GUY", name: "Guyana"},
    { code: "HTI", name: "Haiti"},
    { code: "HMD", name: "Heard Island and McDonald Islands"},
    { code: "VAT", name: "Holy See (the)"},
    { code: "HND", name: "Honduras"},
    { code: "HKG", name: "Hong Kong"},
    { code: "HUN", name: "Hungary"},
    { code: "ISL", name: "Iceland"},
    { code: "IND", name: "India"},
    { code: "IDN", name: "Indonesia"},
    { code: "IRN", name: "Iran (Islamic Republic of)"},
    { code: "IRQ", name: "Iraq"},
    { code: "IRL", name: "Ireland"},
    { code: "IMN", name: "Isle of Man"},
    { code: "ISR", name: "Israel"},
    { code: "ITA", name: "Italy"},
    { code: "JAM", name: "Jamaica"},
    { code: "JPN", name: "Japan"},
    { code: "JEY", name: "Jersey"},
    { code: "JOR", name: "Jordan"},
    { code: "KAZ", name: "Kazakhstan"},
    { code: "KEN", name: "Kenya"},
    { code: "KIR", name: "Kiribati"},
    { code: "PRK", name: "Korea (the Democratic People's Republic of)"},
    { code: "KOR", name: "Korea (the Republic of)"},
    { code: "KWT", name: "Kuwait"},
    { code: "KGZ", name: "Kyrgyzstan"},
    { code: "LAO", name: "Lao People's Democratic Republic (the)"},
    { code: "LVA", name: "Latvia"},
    { code: "LBN", name: "Lebanon"},
    { code: "LSO", name: "Lesotho"},
    { code: "LBR", name: "Liberia"},
    { code: "LBY", name: "Libya"},
    { code: "LIE", name: "Liechtenstein"},
    { code: "LTU", name: "Lithuania"},
    { code: "LUX", name: "Luxembourg"},
    { code: "MAC", name: "Macao"},
    { code: "MDG", name: "Madagascar"},
    { code: "MWI", name: "Malawi"},
    { code: "MYS", name: "Malaysia"},
    { code: "MDV", name: "Maldives"},
    { code: "MLI", name: "Mali"},
    { code: "MLT", name: "Malta"},
    { code: "MHL", name: "Marshall Islands (the)"},
    { code: "MTQ", name: "Martinique"},
    { code: "MRT", name: "Mauritania"},
    { code: "MUS", name: "Mauritius"},
    { code: "MYT", name: "Mayotte"},
    { code: "MEX", name: "Mexico"},
    { code: "FSM", name: "Micronesia (Federated States of)"},
    { code: "MDA", name: "Moldova (the Republic of)"},
    { code: "MCO", name: "Monaco"},
    { code: "MNG", name: "Mongolia"},
    { code: "MNE", name: "Montenegro"},
    { code: "MSR", name: "Montserrat"},
    { code: "MAR", name: "Morocco"},
    { code: "MOZ", name: "Mozambique"},
    { code: "MMR", name: "Myanmar"},
    { code: "NAM", name: "Namibia"},
    { code: "NRU", name: "Nauru"},
    { code: "NPL", name: "Nepal"},
    { code: "NLD", name: "Netherlands (the)"},
    { code: "NCL", name: "New Caledonia"},
    { code: "NZL", name: "New Zealand"},
    { code: "NIC", name: "Nicaragua"},
    { code: "NER", name: "Niger (the)"},
    { code: "NGA", name: "Nigeria"},
    { code: "NIU", name: "Niue"},
    { code: "NFK", name: "Norfolk Island"},
    { code: "MNP", name: "Northern Mariana Islands (the)"},
    { code: "NOR", name: "Norway"},
    { code: "OMN", name: "Oman"},
    { code: "PAK", name: "Pakistan"},
    { code: "PLW", name: "Palau"},
    { code: "PSE", name: "Palestine, State of"},
    { code: "PAN", name: "Panama"},
    { code: "PNG", name: "Papua New Guinea"},
    { code: "PRY", name: "Paraguay"},
    { code: "PER", name: "Peru"},
    { code: "PHL", name: "Philippines (the)"},
    { code: "PCN", name: "Pitcairn"},
    { code: "POL", name: "Poland"},
    { code: "PRT", name: "Portugal"},
    { code: "PRI", name: "Puerto Rico"},
    { code: "QAT", name: "Qatar"},
    { code: "MKD", name: "Republic of North Macedonia"},
    { code: "ROU", name: "Romania"},
    { code: "RUS", name: "Russian Federation (the)"},
    { code: "RWA", name: "Rwanda"},
    { code: "REU", name: "Réunion"},
    { code: "BLM", name: "Saint Barthélemy"},
    { code: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha"},
    { code: "KNA", name: "Saint Kitts and Nevis"},
    { code: "LCA", name: "Saint Lucia"},
    { code: "MAF", name: "Saint Martin (French part)"},
    { code: "SPM", name: "Saint Pierre and Miquelon"},
    { code: "VCT", name: "Saint Vincent and the Grenadines"},
    { code: "WSM", name: "Samoa"},
    { code: "SMR", name: "San Marino"},
    { code: "STP", name: "Sao Tome and Principe"},
    { code: "SAU", name: "Saudi Arabia"},
    { code: "SEN", name: "Senegal"},
    { code: "SRB", name: "Serbia"},
    { code: "SYC", name: "Seychelles"},
    { code: "SLE", name: "Sierra Leone"},
    { code: "SGP", name: "Singapore"},
    { code: "SXM", name: "Sint Maarten (Dutch part)"},
    { code: "SVK", name: "Slovakia"},
    { code: "SVN", name: "Slovenia"},
    { code: "SLB", name: "Solomon Islands"},
    { code: "SOM", name: "Somalia"},
    { code: "ZAF", name: "South Africa"},
    { code: "SGS", name: "South Georgia and the South Sandwich Islands"},
    { code: "SSD", name: "South Sudan"},
    { code: "ESP", name: "Spain"},
    { code: "LKA", name: "Sri Lanka"},
    { code: "SDN", name: "Sudan (the)"},
    { code: "SUR", name: "Suriname"},
    { code: "SJM", name: "Svalbard and Jan Mayen"},
    { code: "SWE", name: "Sweden"},
    { code: "CHE", name: "Switzerland"},
    { code: "SYR", name: "Syrian Arab Republic"},
    { code: "TWN", name: "Taiwan"},
    { code: "TJK", name: "Tajikistan"},
    { code: "TZA", name: "Tanzania, United Republic of"},
    { code: "THA", name: "Thailand"},
    { code: "TLS", name: "Timor-Leste"},
    { code: "TGO", name: "Togo"},
    { code: "TKL", name: "Tokelau"},
    { code: "TON", name: "Tonga"},
    { code: "TTO", name: "Trinidad and Tobago"},
    { code: "TUN", name: "Tunisia"},
    { code: "TUR", name: "Turkey"},
    { code: "TKM", name: "Turkmenistan"},
    { code: "TCA", name: "Turks and Caicos Islands (the)"},
    { code: "TUV", name: "Tuvalu"},
    { code: "UGA", name: "Uganda"},
    { code: "UKR", name: "Ukraine"},
    { code: "ARE", name: "United Arab Emirates (the)"},
    { code: "GBR", name: "United Kingdom of Great Britain and Northern Ireland (the)"},
    { code: "UMI", name: "United States Minor Outlying Islands (the)"},
    { code: "USA", name: "United States of America (the)"},
    { code: "URY", name: "Uruguay"},
    { code: "UZB", name: "Uzbekistan"},
    { code: "VUT", name: "Vanuatu"},
    { code: "VEN", name: "Venezuela (Bolivarian Republic of)"},
    { code: "VNM", name: "Viet Nam"},
    { code: "VGB", name: "Virgin Islands (British)"},
    { code: "VIR", name: "Virgin Islands (U.S.)"},
    { code: "WLF", name: "Wallis and Futuna"},
    { code: "ESH", name: "Western Sahara"},
    { code: "YEM", name: "Yemen"},
    { code: "ZMB", name: "Zambia"},
    { code: "ZWE", name: "Zimbabwe"},
    { code: "ALA", name: "Åland Islands"}
];
