import { Component, OnInit } from "@angular/core";
import { Clipboard } from  "@angular/cdk/clipboard";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { User, Company } from "src/app/common/_models";
import { ServiceUsers, ServiceCompanies } from "src/app/common/_services";

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
  users: User[] = [];
  companies: Company[] = [];
  selected: User | undefined;
  hide: boolean = false;

  // FormControl
  userFormGroup: FormGroup = new FormGroup({
    id: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl(),
    role: new FormControl('', [Validators.required]),
    companyId: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    dateInvited: new FormControl({value: '', disabled: true}),
    dateRegistered: new FormControl({value: '', disabled: true}),
    dateLastLogin: new FormControl({value: '', disabled: true}),
  });



  constructor(
    private userService: ServiceUsers,
    private companyService: ServiceCompanies,
    private clipboard: Clipboard
  ) { }

  ngOnInit() {
    //console.log("Users!");
    this.refresh();
  }

  private refresh(): void {
    this.new();
    this.companyService.getCompanies().subscribe(
      (data: Company[]) => {
        this.companies = data;
      }
    )

    this.userService.getUsers().subscribe(
      (data: User[]) => {
        this.users = data;
        //console.warn("devs: \n" + JSON.stringify(this.users));
      }
    );
  }

  onChange(event: any): void {
    //console.log("event: " + JSON.stringify(event.value));
    let selected: User = event.value;
    this.userFormGroup.controls["password"].disable();

    if (selected.dateInvited) selected.dateInvited = new Date(selected.dateInvited);
    if (selected.dateRegistered) selected.dateRegistered = new Date(selected.dateRegistered);
    if (selected.dateLastLogin) selected.dateLastLogin = new Date(selected.dateLastLogin);

    this.userFormGroup.patchValue(selected);
    this.isNew = false;
  }

  isNew: boolean = true;
  new(): void {
    this.selected = undefined;
    this.userFormGroup.controls["password"].enable();
    this.userFormGroup.reset();
    //console.log("NEW");
    this.isNew = true;
  }

  save(): void {
    //console.log("Is New: " + this.isNew);
    //console.log("User: " + JSON.stringify(this.userFormGroup.value));

    let user: User = this.userFormGroup.value;
    if (this.isNew) {
      this.userService.createNewUser(user).subscribe({
        next: data => {
          //console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      });
    } else {
      this.userService.updateUser(user).subscribe({
        next: data => {
          //console.log("Done " + JSON.stringify(data));
        },
        error: error => {
          console.error(JSON.stringify(error));
        }
      }).add(() => {
        this.refresh();
      });
    }
  }

  copyclipboard(): void {
    //console.log("Copy to clipboard:");

    let user: User = this.userFormGroup.value;
    let userData: string = "BEGIN:VCARD\nVERSION:4.0\n";
    userData += "N:"+this.userFormGroup.value.lastName+";"+user.firstName+"\n";
    //userData += "ORG:\n";
    userData += "TEL;TYPE#work,voice;VALUE#uri:tel:"+user.phone+"\n";
    userData += "EMAIL:"+user.email+"\n";
    //userData += "REV:20080424T195243Z\n";
    userData += "END:VCARD";
    //userData = JSON.stringify(user);
    //console.log(userData);
    this.clipboard.copy(userData)
  }

  delete(): void {
    //console.log("DELETE");
    let user: User = this.userFormGroup.value;
    this.userService.deleteUser(user).subscribe({
      next: data => {
        //console.log("Done " + JSON.stringify(data));
      },
      error: error => {
        console.error(JSON.stringify(error));
      }
    }).add(() => {
      this.refresh();
    });
  }

}
