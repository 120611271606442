import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company, CompanyRelations } from "../_models";
import { ServiceAuth } from "./auth.service";

@Injectable()

export class ServiceCompanies {

  constructor(
    private http: HttpClient,
  ) { }

  getCompany(): Observable<Company> {
    return this.http.get<Company>('/api/admin/company');
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>('/api/root/companies');
  }

  getCompaniesMinimal(): Observable<Company[]> {
    return this.http.get<Company[]>('/api/root/companies?minimal');
  }

  createNewCompany(company:Company):Observable<any> {
    return this.http.post<any>('/api/root/companies', company);
  }

  updateCompany(company:Company):Observable<any> {
    let user = ServiceAuth.getLocalUser();
    if (user.role === "root") {
      return this.http.put<any>('/api/root/companies', company);
    }
    else if (user.role === "admin") {
      return this.http.put<any>('/api/admin/company', company)
    }
    return new Observable<any>();
  }

  deleteCompany(company:Company):Observable<any> {
    return this.http.delete<any>('/api/root/companies/'+company.id);
  }

  getConsults(company:Company): Observable<Company[]> {
    return this.http.get<Company[]>('/api/root/consults?id=' + company.id);
  }

  getConsultees(company:Company): Observable<Company[]> {
    return this.http.get<Company[]>('/api/root/consultees?id=' + company.id);
  }

  updateCompanyConsults(relations: CompanyRelations): Observable<any> {
    return this.http.put<any>('/api/root/consults', relations);
  }

  updateCompanyConsultees(relations: CompanyRelations): Observable<any> {
    return this.http.put<any>('/api/root/consultees', relations);
  }
}