import { Component, Input, OnInit } from "@angular/core";
import FileSaver from "file-saver";
import { DeviceInfo, ExportFormat, FileExport, ServiceInfo } from "../../_models";
import { ServiceExport } from "../../_services";

interface Option {
  value: number;
  text: string;
}

@Component({
  selector: 'tool-export-big-data',
  templateUrl: './export-big-data.html',
  styleUrls: ['./export-big-data.scss']
})

export class ExportBigDataTool implements OnInit {
  durationOptions: Option[] = [
    { value: 60 * 1000, text: "1 m" },
    { value: 5 * 60 * 1000, text: "5 m" },
    { value: 10 * 60 * 1000, text: "10 m" },
    { value: 15 * 60 * 1000, text: "15 m" },
    { value: 30 * 60 * 1000, text: "30 m" },
    { value: 60 * 60 * 1000, text: "60 m" },
    { value: 1 * 60 * 60 * 1000, text: "1 h" },
    { value: 2 * 60 * 60 * 1000, text: "2 h" },
    { value: 6 * 60 * 60 * 1000, text: "6 h" },
    { value: 12 * 60 * 60 * 1000, text: "12 h" },
    { value: 24 * 60 * 60 * 1000, text: "24 h" },
  ];

  fileSizeOptions: Option[] = [
    { value: 1000, text: "1s" },
    { value: 10000, text: "10s" },
    { value: 30000, text: "30s" },
    { value: 60000, text: "1m" },
    { value: 5 * 60000, text: "5m" },
    { value: 10 * 60000, text: "10m" },
    { value: 15 * 60000, text: "15m" },
  ];

  exportFormats: ExportFormat[] = [];
  loading: boolean = false;
  format?: string;
  from: number = 0;
  duration: number = this.durationOptions[0].value;
  fileSize: number = this.fileSizeOptions[0].value;
  to: number = this.from + this.duration;
  ready: boolean = false;
  _devices: DeviceInfo[] = [];
  _services: ServiceInfo[] = [];

  @Input() set dataFrom(value: Date | undefined) {
    if (value != undefined) {
      this.from = value?.getTime();
      this.updateEndDate();
    }
  }


  @Input() set devices(value: DeviceInfo[]) {
    this._devices = value;
  }

  @Input() set services(value: ServiceInfo[] | undefined) {
    if (value != undefined) {
      this._services = value;
    }
  }

  constructor(
    private exportService: ServiceExport
  ) { }

  ngOnInit(): void {
    this.loadFormats();
  }

  private loadFormats(): void {
    this.loading = true;
    this.exportService.getFormats().subscribe({
      next: (data: ExportFormat[]) => {
        this.exportFormats = data;
        this.format = this.exportFormats[0].format;
      },
      error: error => {
        this.exportFormats = [
        ];
      }
    }).add(() => { this.loading = false; });
  }

  updateEndDate(): void {
    console.log("updateEndDate");
    this.to = this.from + this.duration;
  }

  running: boolean = false;
  toggleStartStop(): void {
    this.running = !this.running;
    this.requestLoop();
  }


  loopIndex: number = 0;
  totalLoops: number = 0;
  errorLoopCounter: number = 0;

  private requestLoop(loop?: boolean): void {
    if (!this.running) return;
    if (!loop) {
      this.loopIndex = 0;
      this.totalLoops = (this.to - this.from) / this.fileSize;
      //console.error(this.totalLoops);
    }
    //console.log(this.loopIndex + " " + this.from + " to " + this.to);
    let fromDate: number = this.from + this.loopIndex * this.fileSize + new Date().getTimezoneOffset() * 60000;
    let toDate: number = fromDate + this.fileSize;
    if (toDate > this.to) toDate = this.to;

    this.exportService.export(this.format!, new Date(fromDate), new Date(toDate), this._devices, this._services).subscribe(

      (data: FileExport[]) => {
        data.forEach(
          file => {
            let blob: Blob;
            const byteCharacters = atob(file.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            blob = new Blob([byteArray], { type: file.type });
            let nameExt: string[] = file.name.split(".", 2);
            //console.log(nameExt[0] + "-" + new Date(fromDate - new Date().getTimezoneOffset() * 60000 * 2).toISOString() + "-" + nameExt[1]);
            let fileName = nameExt[0] + "-" + new Date(fromDate - new Date().getTimezoneOffset() * 60000 * 2).toISOString() + "-" + new Date(toDate - new Date().getTimezoneOffset() * 60000 * 2).toISOString() + "." + nameExt[1]
            //console.log(byteArray.length + " " + file.name);
            FileSaver.saveAs(blob, fileName);
          }
        );

        this.errorLoopCounter = 0;
        this.loopIndex++;
        console.log((this.from + this.loopIndex * this.fileSize) + " VS " + this.to);

        if ((this.from + this.loopIndex * this.fileSize) >= this.to) this.running = false;
        this.requestLoop(true);

      },
      (error: Error) => {
        if (this.errorLoopCounter < 4) {
          this.errorLoopCounter++;
          this.requestLoop(true);
        }
      }
    );


  }


}