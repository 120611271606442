import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Invite, User } from "../_models";



@Injectable()

export class ServiceUser {
  constructor(
    private http: HttpClient
  ) {
  }

  updateUser(user:User): Observable<any> {
    return this.http.put<any>('/api/user', user);
  }

  stageUser(user:User): Observable<any> {
    return this.http.post<any>('/api/user/stage', user);
  }

  inviteUser(invite:Invite): Observable<any> {
    return this.http.post<any>('/api/admin/invite', invite);
  }
}