import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceInfo, DataInfo, DataFileList, DataSource } from "../_models";

@Injectable()

export class ServiceData {

  constructor(
    private http: HttpClient,
  ) { }

  getFiles(path: string): Observable<DataFileList> {
    let files = this.http.get<DataFileList>('/api/data/storage/files' + path);
    return files;
  }

  getServicesOf(devices: DeviceInfo[]): Observable<DataInfo> {
    return this.http.get<DataInfo>('/api/data/info?ids=' + devices.map(d => d.bn).join(','));
  }

  getDataOf(devices: string[], services: string[], from: number, to: number, limit?: number): Observable<DataSource[]> {
    let url: string = '/api/data?ids=' + devices.join(',') + '&services=' + services.join(',') + '&from=' + from + '&to=' + to;
    if (limit) url += '&limit=' + limit;
    console.warn(url);
    return this.http.get<DataSource[]>(url);
  }

  downloadFile(path: string, fileName: string): Observable<any> {
    let url: string = '/api/data/storage/download' + path + "/" + fileName;
    return this.http.get<any>(url, { responseType: "blob" as "json" });
  }

}