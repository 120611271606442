import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthLogin, Token, User } from "../_models";
import { Md5 } from "ts-md5";


const COOKIE_NAME: string = "TwNucleusUser";

@Injectable()

export class ServiceAuth {

  constructor(
    private http: HttpClient,
  ) { }

  static getLocalUser(): User {
    let cookie = localStorage.getItem(COOKIE_NAME);
    let user: User = cookie !== null ? JSON.parse(cookie) : null;

    return user;
  }

  getLocalUserToken(): Token | null {
    let user: User = ServiceAuth.getLocalUser();
    //console.warn(JSON.stringify(user));
    if (user == null)
        return null;
    if (user.token == null)
        return null;

    return user.token;
  }

  imRoot(): boolean {
    return ServiceAuth.getLocalUser().role == "root";
  }

  login(authLogin: AuthLogin): Observable<User> {
    authLogin.password = this.hash(authLogin.password);
    return this.http.post<User>('/api/login', authLogin).pipe(
      map(
        data => {
          //auth.service.tsconsole.warn(JSON.stringify(data));
          if (data.token) {
            localStorage.setItem(COOKIE_NAME, JSON.stringify(data));
          }
          return data;
        }
      )
    );
  }

  logout(): void {
    console.error("auth LOG OUT ");
    localStorage.removeItem(COOKIE_NAME);
  }

  private hash(toHash: string): string {
    return "" + Md5.hashStr(toHash); // TODO: Hashing should be done on the backend.
  }

}
