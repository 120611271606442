import { ChangeDetectorRef, Component } from "@angular/core";
import { MediaMatcher } from '@angular/cdk/layout';
import { User } from "../common/_models";
import { ServiceAuth } from "../common/_services";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomSheetUserConf } from "./bottomsheets";


const TIMEOUT: number = 2000;

@Component({
  templateUrl: './nucleus.component.html',
  styleUrls: ['./nucleus.component.scss']
})

export class NucleusComponent {
  private intervalId: NodeJS.Timeout;
  private initDate: number;
  showProtectBanner: boolean = true;
  public protectCounter: number = 0;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  user?: User;

  constructor(
    private bottomSheet: MatBottomSheet,
    changeDetectorRef: ChangeDetectorRef,
    private authService: ServiceAuth,
    private media: MediaMatcher,
  ) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.initDate = new Date().getTime();
    this.intervalId = setInterval(() => this.countDown(), 100);
    
    this.user = ServiceAuth.getLocalUser();

  }

  private countDown() {
    let time = new Date().getTime() - this.initDate;
    if (time > TIMEOUT) {
      clearInterval(this.intervalId);
      this.showProtectBanner = false;
    }
    this.protectCounter = time * 100 / TIMEOUT;

  }

  userMenu() {
    let bottomSheetRef = this.bottomSheet.open(BottomSheetUserConf, { data: this.user });
    bottomSheetRef.afterDismissed().subscribe(
      data => {
      }
    );
  }

}
