import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// Other Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

let Material = [
  MatBottomSheetModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule
];

// Highcharts
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highmaps from 'highcharts/modules/map.src';
import * as highstock from 'highcharts/modules/stock.src';

let ExternalComponents = [
  ChartModule
];

// Plotly - FFT Charts
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';

PlotlyModule.plotlyjs = PlotlyJS;

// External Services
let HighChartsProviders = { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, highstock, highmaps] };

let ExternalServices = [
  HighChartsProviders
];

// Services
import {
  ServiceArrowhead,
  ServiceAuth,
  ServiceData,
  ServiceDevice,
  ServiceDevices,
  ServiceShadowDevices,
  ServiceExport,
  ServiceInfo,
  ServiceUser,
  ServiceMaps,
  ServiceCameras,
  ServiceCompanies,
  ServiceUsers,
  ServiceEmployee
} from './common/_services';

let Services = [
  ServiceArrowhead,
  ServiceAuth,
  ServiceData,
  ServiceDevice,
  ServiceDevices,
  ServiceShadowDevices,
  ServiceExport,
  ServiceInfo,
  ServiceUser,
  ServiceMaps,
  ServiceCameras,
  ServiceCompanies,
  ServiceUsers,
  ServiceEmployee
];

// Guards
import {
  GuardAuth
} from './common/_guards';
let Guards = [
  GuardAuth
];

// Interceptors
import {
  InterceptorAuth,
  InterceptorJWT
} from './common/_interceptors';

let Interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorAuth,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorJWT,
    multi: true
  }
];

// Components
import {
  LoginComponent
} from './login';

import {
  NucleusComponent,
  DashboardComponent,
  OsmmapComponent,
  DevicesComponent,
  ShadowsComponent,
  ArrowheadComponent,
  NucalarmsComponent,
  StorageComponent,
  CamerasComponent,
  ReportsComponent,
  DocumentationComponent,
  LogsComponent,
  CompaniesComponent,
  CompanyComponent,
  UsersComponent
} from './nucleus';

let Components = [
  AppComponent,
  ArrowheadComponent,
  LoginComponent,
  NucleusComponent,
  DashboardComponent,
  NucalarmsComponent,
  StorageComponent,
  OsmmapComponent,
  DevicesComponent,
  ShadowsComponent,
  CamerasComponent,
  ReportsComponent,
  DocumentationComponent,
  LogsComponent,
  CompaniesComponent,
  CompanyComponent,
  UsersComponent
];

// Lists
import {
  ListConsultees,
  ListConsults,
  ListDevices,
  ListEmployees,
  ListFiles
} from './nucleus/list';

let Lists = [
  ListConsultees,
  ListConsults,
  ListDevices,
  ListEmployees,
  ListFiles
];

// Tools
import {
  ExportTool,
  ExportBigDataTool,
  FftGraph,
  ToolsFilesDrop,
  ToolsImageAutorefresh,
  ToolTimeDaySlider,
} from './common/_tools';

let Tools = [
  ExportTool,
  ExportBigDataTool,
  FftGraph,
  ToolsFilesDrop,
  ToolsImageAutorefresh,
  ToolTimeDaySlider
];

// BottomSheets
import {
  BottomSheetData,
  BottomSheetUserConf,
  BottomSheetStageUser
} from './nucleus/bottomsheets';

let BottomSheets = [
  BottomSheetData,
  BottomSheetUserConf,
  BottomSheetStageUser
];

@NgModule({
    declarations: [
        BottomSheets,
        Components,
        Lists,
        Tools
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ExternalComponents,
        FormsModule,
        HttpClientModule,
        Material,
        PlotlyModule,
        ReactiveFormsModule,
    ],
    exports: [
        Material
    ],
    providers: [
        Guards,
        ExternalServices,
        Interceptors,
        Services,
        Tools
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
