<div class="container-shadowMgt">
  <mat-form-field class="half-width">
    <mat-label>ShadowDevice</mat-label>
    <mat-select (selectionChange)="onChange($event)" [(ngModel)]="selected">
      <mat-option *ngFor="let dev of devices" [value]="dev">{{dev.bn}}</mat-option>
    </mat-select>
  </mat-form-field>

  <form [formGroup]="shadowFormGroup" (submit)="save()">
    <ng-template matStepLabel>Enter your password</ng-template>
    <div class="login-fields">
      <div>
        <mat-form-field class="half-width">
          <mat-label>Bn</mat-label>
          <input matInput formControlName="bn" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>UUID</mat-label>
          <input matInput formControlName="uuid" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Alias</mat-label>
          <input matInput formControlName="alias" >
        </mat-form-field>
      </div>
<!--
      <div>
        <mat-form-field class="half-width">
          <mat-label>User name</mat-label>
          <input matInput formControlName="userName" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility'}} </mat-icon>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="half-width">
          <mat-label>Cliend Id</mat-label>
          <input matInput formControlName="clientId" required>
        </mat-form-field>
      </div>
-->
      <div>
<!--        <mat-form-field class="half-width">
         <mat-label>Enabled</mat-label>
          <input matInput formControlName="clientId" required>-->
          <mat-checkbox formControlName="enabled" color="primary">Enabled</mat-checkbox>
<!--        </mat-form-field>-->
      </div>
    </div>

    <div align="end">
      <button mat-raised-button type="button" color="warn" (click)="delete()" [disabled]="isNew">Delete </button>
      <button mat-raised-button type="button" (click)="new()">New </button>
      <button mat-raised-button type="submit" color="primary" [disabled]="!shadowFormGroup.valid">Save </button>
      <button mat-raised-button type="button" (click)="copyclipboard()">Copy </button> <!-- copy JSON object to clipboard -->
    </div>

  </form> 

  <div>
    Here users can add, delete and manage Shadow devices. A Shadow device is a virtual device that can be used as an endpoint for analytics, fetching external data over MQTT, CoAP, HTTP, OPC-UA, Modbus, etc.
  </div>

</div>
