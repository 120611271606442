// Core Modules
import { Component, HostListener, Output, EventEmitter, Input } from '@angular/core';

// Common
import { File } from 'src/app/common/_models';

@Component({
  selector: 'tools-files-drop',
  templateUrl: './files-drop.tools.html',
  styleUrls: ['./files-drop.tools.scss']
})


export class ToolsFilesDrop {
  public shakeIt: boolean = false;
  private files: File[] = [];

  @Input() title: string = "";
  @Input() formats: string = "";

  @Output() droppedFilesEvent = new EventEmitter();

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.shakeIt = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.shakeIt = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.shakeIt = false;
    this.readFiles(event.dataTransfer.files);
  }

  constructor() {
  }

  fileBrowser(event: any) {
    this.readFiles(event.target.files);
  }

  private readFiles(filesRef: any): void {
    this.files = [];
    this.title = "";
    for (let fileRef of filesRef) {
      let reader = new FileReader();
      reader.readAsDataURL(fileRef);
      reader.onload = () => {
        let header: string = (<string>reader.result).split(',')[0];
        let file: File = new File();
        file.data._id = "";
        file.data.dataB64 = (<string>reader.result).split(',')[1];
        file.info._id = "";
        file.info._dataId = "";
        file.info.name = fileRef.name;
        file.info.size = fileRef.size;
        file.info.type = (<string>header.split(';')[0]).split(':')[1];
        file.info.base = header.split(';')[1];
        this.files.push(file);
        this.title += " "+file.info.name;
        this.checkIfNotify(filesRef.length);
      };
    }
  }

  private checkIfNotify(totalFiles: number) {
    if (this.files.length == totalFiles) {
      this.droppedFilesEvent.emit(this.files);
    }
  }



}